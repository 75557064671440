.product-page {

}

.product-options {

  .block-title {
    display: block;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0.875rem;
  }

  .product-header {

    h2 {
      font-size: 1.25rem;
      // text-transform: uppercase;
      color: $blue;
      margin: 0;
      span{
        font-size: 1.75rem;
        color: $red-c;
        font-weight: 700;
        padding-bottom: 10px;
        display: inline-block;
      }
    }

    .code-block{
      padding: 20px 0;
      .code{
        font-size: 1rem;
        font-weight: 700;
        margin: 0;
        span{
          color: #DE6E6E;
        }
      }
    }
    .price-block {
      font-weight: 700;
      padding: 20px 0;
      border-bottom: 1px solid $gray-c;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .price-title{
        margin: 0;
        font-weight: 400;
        font-size: 1rem;
      }
      .price {
        display: inline-block;
        color: $color1-c;
        font-size: 1.75rem;
        margin: 0 5px;
      }

      .old-price {
        display: inline-block;
        padding: 0 5px;
        position: relative;
        font-size: 1.125rem;
        &:before {
          content: '';
          position: absolute;
          left: 0; top: 50%;
          width: 100%; height: 1px;
          background-color: $color2-c;
        }
      }
    }

    .description {
      padding: 20px 0;
      border-top: 1px solid $gray-c;
      border-bottom: 1px solid $gray-c;

      p {
        margin-bottom: 0;
      }
      .icon-block{
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
      }
    }
  }

  .product-cart {
    padding: 1.25rem 0;
    border-bottom: 1px solid $gray-c;

    .selectboxes-holder {
      .select {
        margin-bottom: 1.25rem;
      }
    }

    .product-links-holder {
      padding-top: 1.25rem;

      ul{

        li {
          float: left;
          margin-right: 10px;

          &:last-child{
            margin-right: 0;
          }

          a {
            display: inline-block;
            text-decoration: none;
            padding-left: 50px;
            position: relative;
            line-height: 40px;

            span{
              position: absolute;
              left: 0; top: 0;
              font-size: 19px;
              height: 40px; width: 40px;
              text-align: center;
              line-height: 38px;
              border: 1px solid $gray-c;
              display: inline-block;
              vertical-align: middle;
              color:  #888;
              @include transition-all(0.4s);
            }

            &:hover{
              color: $color1-c;

              span {
                border-color: $color1-c;
                background-color: $color1-c;
                color: $white-c;
              }
            }
          }

        }
      }
    }
  }

  .product-footer {
    padding: 20px 0;
    border-bottom: 1px solid $gray-c;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .wish{
      span{
        width: 40px;
        height: 40px;
        border: 1px solid $border-c;
        margin-right: 10px;
        text-align: center;
        line-height: 38px;
      }
    }
    .categories-holder {

      a {
        display: inline-block;

        &:hover {
          color: $color1-c;
        }
      }
    }

    .social-links-holder {
      display: flex;
      align-items: center;
      .c-title{
        margin-right: 10px;
      }
      .social-links {
        display: block;
        @extend .clearfix;

        li {
          display: inline-block;
          float: left;

          &:not(:first-of-type) {
            a {
              border-left: 0;
            }
          }
        }

        a {
          display: block;
          line-height: 38px;
          width: 40px;
          border: 1px solid $border-c;
          color: $blue;
          text-align: center;
          font-size: 1rem;

          &:hover {
            border-color: $blue;
            background-color: $blue;
            color: $white-c;
          }
        }
      }
    }
  }
}

.send-to-friend {
  text-align: left;

  h2{
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  h3{ 
    font-size: 1rem; 
  }

  .c-textarea {
    min-height: 120px;
    padding: 10px 15px;
  }
}

.modal-send-to-friend {
  .close {
    position: absolute;
    right: -17px;
    top: -17px;
    width: 45px;
    height: 45px;
    text-indent: -99999px;
    opacity: .85;
    background: url('../images/close-quick.png');
    border: none;
    cursor: pointer;
  }
}

.counter-holder{
  position: relative;
  padding: 0 0 0 107px;

  & .product-counter{
    border: 1px solid $gray-c;
    padding-right: 30px;
    position: absolute;
    left: 0; top: 0;
    display: inline-block;
    width: 90px;

    input{
      border: 0 none;
      float: left;
      line-height: 25px;
      padding: 9px 0;
      text-align: center;
      width: 58px;
    }

    div{
      border-left: 1px solid $border-c;
      cursor: pointer;
      font-size: 15px;
      line-height: 100%;
      padding: 3px 0;
      position: absolute;
      right: 0;
      text-align: center;
      width: 30px;
      -moz-user-select: none;
      -khtml-user-select: none;
      user-select: none;
      background-color: $blue;
      color: $white-c;
      &:hover{
        background-color: $color1-c;
      }
      &.addQuantity{
        border-bottom: 1px solid $gray-c;
        top: 0;
      }
      &.minusQuantity{
        bottom: 0;
      }

    }
  }

  .large-button{
    font-size: 1rem;
    text-transform: uppercase;
    color: $white-c;
    text-transform: uppercase;
    background-color: $blue;
    text-align: center;
    line-height: 33px;
    display: block;
    width: 100%;
    padding: 5px 10px;
    border: 1px solid $blue;
    cursor: pointer;
    @include transition-all(0.4s);
    &:hover{
      background-color: $color1-c;
      border-color: $color1-c;
      color: $white-c;
    }
  }
}

.not-in-stock{
  font-size: 1rem;
  font-weight: bold;
  color: $color1-c;
  padding-bottom: 20px;
  border-bottom: 1px solid $gray-c;
}

@include media-breakpoint-down(md) {
  .product-options {
    margin-top: 30px;
  }
}

@include media-breakpoint-down(sm) {
  .best-sellers {
    margin-top: 30px;

    .text-block {
      padding-right: 10px;
    }
  }
}


.product-category-page {
  .filter-title-b{
    display: none;
    &.active {
      .plus-minus:after {
        @include transform(rotate(0deg));
        @include transition-all(0.4s);
      }
    }

    h3{
      font-size: 1.5rem;
      color: $white-c;
      background-color: $blue;
      font-weight: 400;
      padding: 6px 20px;
      line-height: 30px;
      margin: 0;
      cursor: pointer;
      position: relative;

      .plus-minus {
        cursor: pointer;
        position: absolute;
        right: 3px;
        top: 4px;
        width: 30px;
        height: 30px;
        display: inline-block;
        padding: 0;
        //    border-bottom: 1px solid $white-c;
        z-index: 1;

        &:before {
          content: '';
          position: absolute;
          width: 17px;
          height: 3px;
          right: 7px;
          @include center(y);
          background-color: $white-c;
        }
        &:after {
          content: '';
          position: absolute;
          width: 17px;
          height: 3px;
          right: 7px;
          @include center(y);
          @include transform(rotate(90deg));
          background-color: $white-c;
          margin-top: -1px;
          @include transition-all(0.4s);
        }
        &.trigger-active {
          &:after {
            @include transform(rotate(0deg));
            @include transition-all(0.4s);
          }
        }
      }
      @include media-breakpoint-down(sm) {
        display: block;
        margin-bottom: 10px;
      }
    }
    .sort-block{
      display: flex;
    }
    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
  .filter-title-box{
    //margin-top: 10px;
    &.active{
      display: block;
    }
  }
  .sidebar-block {
    margin-bottom: 30px;

    .block-title {
      font-size: 1.125rem;
      font-weight: 700;
      text-transform: uppercase;
      color: $white-c;
      background-color: $blue;
      padding: 10px;
      margin-bottom: 0;
    }
    @include media-breakpoint-down(md){
      margin-bottom: 10px;
    }
    @include media-breakpoint-down(xs){
      #categories{
        .block-title{
          display: none;
        }
      }
    }
  }
  .product-list-options-trigger{
    display: none;

    font-size: 1.5rem;
    color:$black-c ;
    background-color:$white-c;
    border: 1px solid $blue;
    padding: 5px 20px;
    line-height: 30px;
    margin: 0;
    cursor: pointer;
    position: relative;
    margin-bottom: 25px;

    .plus-minus {
      cursor: pointer;
      position: absolute;
      right: 3px;
      top: 4px;
      width: 30px;
      height: 30px;
      display: inline-block;
      padding: 0;
      //    border-bottom: 1px solid $white-c;
      z-index: 1;

      &:before {
        content: '';
        position: absolute;
        width: 17px;
        height: 3px;
        right: 7px;
        @include center(y);
        background-color: $blue;
      }
      &:after {
        content: '';
        position: absolute;
        width: 17px;
        height: 3px;
        right: 7px;
        @include center(y);
        @include transform(rotate(90deg));
        background-color: $blue;
        margin-top: -1px;
        @include transition-all(0.4s);
      }
      &.trigger-active {
        &:after {
          @include transform(rotate(0deg));
          @include transition-all(0.4s);
        }
      }
    }

  }
  .product-category-list-options2{
    display: none;
  }
  @include media-breakpoint-down(sm) {
    .filter-title-box{
      display: none;
      &.class1{
        display: block;
      }
    }
    .product-list-options-trigger{
      display: block;
      width: 100%;
      flex: 1;
      margin-right: 10px;
      margin-bottom: 0;
    }
    .product-category-list-options{display: none;}
    .product-category-list-options2{
      display: none;
      margin-top: 10px;
      .select {
        margin-bottom: 10px;
      }
    }
  }
}

.sidebar-filters {
  border-bottom: 1px solid $gray-c;
  h4 {
    font-weight: 400;
    position: relative;
    padding: 10px 30px 10px 10px;
    margin-bottom: 0;
    text-transform: uppercase;
    cursor: pointer;
    line-height: 30px;
    font-size: 0.875rem;
    .plus-minus {
      cursor: pointer;
      position: absolute;
      right: 0; top: 10px;
      width: 25px;
      height: 25px;
      display: inline-block;
      padding: 0;
      //    border-bottom: 1px solid $white-c;
      z-index: 1;
      &:before {
        content: '';
        position: absolute;
        width: 10px; height: 2px;
        right: 7px;
        @include center(y);
        background-color: $black-c;
      }
      &:after {
        content: '';
        position: absolute;
        width: 10px; height: 2px;
        right: 7px;
        @include center(y);
        @include transform(rotate(90deg));
        background-color: $black-c;
        margin-top: -1px;
        @include transition-all(0.4s);
      }
      &.trigger-active {
        &:after {
          @include transform(rotate(0deg));
          @include transition-all(0.4s);
        }
      }
    }
  }
  .checkboxes-holder {
    padding: 5px 10px 15px 10px;
    label {
      text-align: left!important;
    }
  }
}


.sidebar-filters-options {
  a {
    display: block;
    font-size: 0.875rem;
    cursor: pointer;
    text-transform: uppercase;
    padding-left: 0; padding-right: 0;
  }
}

/* Categories */
.categories-block{
  position: relative;
  z-index: 1;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  > ul {
    border-top: 1px solid #efefef;
  }

  li{
    position: relative;
    display: block;
   
  }

  a{
    display: block;
    padding: 10px;
    line-height: 20px;
    font-size: 1rem;
    text-transform: uppercase;
    color: $blue;
    position: relative;
    border-bottom: 1px solid #efefef;
    font-weight: 700;
    &:hover,
    &:focus{
      background-color: $blue;
      color: $white-c!important;
      text-decoration: none;
      &::before {
        background-color: $white-c;
      }
    }

    img{
      max-width: 30px;
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .active > a {
    background-color: $color1-c;
    color: $white-c;
    &::before {
      background-color: $white-c;
    }
    &:hover,
    &:focus{
      background-color: $color1-c;
      color: $white-c;
    }
  }

  i{
    display: none;
    position: absolute;
    @include center(y);
    right: 10px;
  }

  ul > li {

    > ul {
      background-color: #fff;
      > li{
        a{
          padding: 10px 20px 10px 27px;
          font-weight: 600;
          font-size: 0.875rem;
          color: $color2-c;
          &::before {
            content: "";
            height: 1px;
            width: 8px;
            background-color: $black-c;
            font-size: 14px;
            position: absolute;
            left: 12px;
            top: 50%;
            transform: translate(0,-50%);
            -webkit-transition: all .2s ease-in-out;
            -moz-transition: all .2s ease-in-out;
            -ms-transition: all .2s ease-in-out;
            -o-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
          }
        }
        > ul {
          background-color: #fff;
          > li{
            a{
              padding: 10px 20px 10px 37px;
              font-weight: 400;
              color: #555555;
              &::before {
                left: 22px;
              }
            }
            
          }
        }
      }
    }
  }
}

.view-type {
  display: block;
  text-align: right;

  li {
    display: inline-block;
    width: 40px; height: 40px;
    font-size: 20px;

    a {
      line-height: 38px;
      display: block;
      text-align: center;
      border: 1px solid $border-c;
      &.active{
        border-color: $color1-c;
        background-color: $color1-c;
        color: $white-c;
      }
    }

    &.view-grid {
      margin-right: 6px;
    }
  }
}
.categories-big{
  margin-bottom: 15px;
  .button-filter{
    font-size: 1.5rem;
    background-color: #333;
    padding: 9px 20px;
    line-height: 30px;
    margin: 0;
    color: #fff;
    cursor: pointer;
    margin-bottom: 20px;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}
.product-category-list-options {
  @extend .clearfix;
  margin-bottom: 40px;

  @include media-breakpoint-only(xl) {
    .pos-helper {
      position: relative;
      padding-right: 120px;

      .view-type-holder {
        width: 90px;
        position: absolute;
        right: -105px; top: 0;
        padding: 0;
      }
    }
  }

  @include media-breakpoint-between(sm, lg) {
    .pos-helper {
      > div .row {
        margin-top: 20px;
      }
    }
  }

  .border {
    @extend .clearfix;
    padding-bottom: 20px;
    border-bottom: 1px solid $border-c;
  }

  @include media-breakpoint-down(xs) {
    .select {
      margin-bottom: 20px;
    }
  }
}

.product-category-list {
  &.flex-wrap{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  > div {
    @include media-breakpoint-down(md) {
      &:nth-of-type(2n+1) {
        clear: both;
      }
    }
    @include media-breakpoint-up(lg) {
      &:nth-of-type(3n+1) {
        clear: both;
      }
    }
  }

  .list-c-tooltip {
    display: none;
  }

  .product-preview-item {
    margin-bottom: 30px;
  }

  &.list-view {

    .list-c-tooltip {
      display: block;
      position: absolute;
      left: 100%;
      width: 200px;
      top: 50%;
      transform: translateY(-50%);
      padding-left: 20px;
      font-size: 1rem;
    }

    > div {
      width: 100%;
    }

    .product-preview-item {
      position: relative;
      border-bottom: 0;
      padding-left: 300px;

      &:before {
        display: none;
      }

      &:hover {
        .text-block:before {
          width: 100%;
        }
      }

      .image-block {
        position: absolute;
        left: 0; top: 0;
        width: 270px;
      }

      .text-block {
        padding: 0;
        text-align: left;
        min-height: 360px;
        padding-bottom: 90px;
        position: relative;
        border-bottom: 1px solid $border-c;

        &:before {
          content: '';
          position: absolute;
          bottom: -1px; left: 50%;
          @include translate(-50%, 0);
          width: 0; height: 1px;
          background-color: $color2-c;
          @include transition-all(0.4s);
        }

        h3 {
          text-transform: uppercase;
          font-size: 1.25rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: 700;
          margin-bottom: 5px;
          a {

          }
        }

        .description {
          display: block;
        }

        .price-block {
          .price {
            margin: 0 5px 0 0;
          }
        }
      }

      .options-block {
        text-align: left;
        color: $gray;
        background-color: transparent;
        position: absolute;
        width: 100%;
        opacity: 1; bottom: 20px;

        ul {
          background: transparent;

          > li {
            height: 50px;
            width: auto;
            display: inline-block;
            position: relative;
            margin-right: 10px;
            border: 1px solid $gray-c;

            &:nth-of-type(1) .c-tooltip {
              display: none!important;
            }

            &:nth-of-type(2) {
              display: none;
            }

            a {
              display: block;
              line-height: 28px;
              padding: 10px 15px;
              color: #888;
              font-size: 1.25rem;
              .only-list-view {
                font-size: 0.875rem;
                display: inline-block;
              }

              &:hover {
                background-color: $color2-c;
                border-color: $color2-c;
                color: $white-c;
              }
            }
          }
        }
      }
    }

    @include media-breakpoint-only(lg) {

      .product-preview-item {
        padding-left: 240px;

        .image-block {
          width: 210px;
        }

        .text-block {
          min-height: 280px;
        }
      }
    }

    @include media-breakpoint-between(sm, md) {

      .product-preview-item {
        padding-left: 180px;

        .image-block {
          width: 150px;
        }

        .text-block {
          min-height: 200px;
        }
      }
    }

    @include media-breakpoint-only(xs) {

      .product-preview-item {
        padding-left: 0;

        .image-block {
          position: relative;
          display: block;
          width: 210px;
          margin: 0 auto;
        }

        .text-block {
          min-height: 1px;
          margin-top: 1rem;
          padding-bottom: 10px;
        }


        .options-block {
          position: relative;
          bottom: 0;
        }
      }
    }


  }
  .list-img{
    text-align: center;
    img{
      display: block;
      margin: 0 auto;
    }
    span{
      display: block;
      text-align: center;
      font-size: 1.5rem;
      font-weight: 700;
    }
  }
}
.product-brend-title{
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  h1 {
    font-weight: 400;
    position: relative;
    padding: 0 40px 20px 0;
    line-height: 30px;
    font-size: 1.55rem;
    margin: 0;
    color: $blue;
    cursor: pointer;

    .plus-minus {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 50%;
      width: 30px;
      height: 30px;
      display: inline-block;
      padding: 0;
      margin-top: -22px;
      z-index: 1;
      &:before {
        content: '';
        position: absolute;
        width: 22px;
        height: 4px;
        right: 0;
        @include center(y);
        background-color: $red-c;
      }
      &:after {
        content: '';
        position: absolute;
        width: 22px;
        height: 4px;
        right: 0;
        @include center(y);
        @include transform(rotate(90deg));
        background-color: $red-c;
        margin-top: -2px;
        @include transition-all(0.4s);
      }
      &.trigger-active {
        &:after {
          @include transform(rotate(0deg));
          @include transition-all(0.4s);
        }
      }
    }
  }
}
.title-parallax {
  height: 150px;
  @extend .parallax-effect;
  text-align: center;
  background-color: $blue;
  background-attachment : inherit;

  .text {
    width: 100%;
    position: absolute;
    @include center(xy);
  }

  h1 {
    font-size: 2rem;
    font-weight: 800;
    color: $blue;
    text-transform: uppercase;
    box-shadow: 1px 1px 3px $black-c;
    background: rgba(255,255,255,0.8);
    display: inline-block;
    padding: 10px;

    @include media-breakpoint-down(lg) {
      font-size: 1.5rem;
    }
  }
  @include media-breakpoint-down(md){
    height: 100px;
  }
}

.page-title-holder {
  position: relative;
  top: 20px;

  h1 {
    font-size: 2rem;
    font-weight: 600;
    text-transform: uppercase;
    @include media-breakpoint-down(lg) {
      font-size: 1.5rem;
    }
  }
}
.title-bg{
  height: 150px;
  text-align: center;
  background-size: cover;
  position: relative;
  .text {
    width: 100%;
    position: absolute;
    @include center(xy);
  }
  h1 {
    font-size: 2rem;
    font-weight: 600;
    color: $white-c;
    text-transform: uppercase;
    text-shadow: 1px 1px 3px $black-c;
  }
}
//////////////////////////////////////////////////////////
//SIMPLE PAGE
//////////////////////////////////////////////////////////
.simple-page {

  .social-links {
    display: block;
    @extend .clearfix;
    margin-top: 20px;

    li {
      display: inline-block;
      float: left;

      &:not(:first-of-type) {
        a {
          border-left: 0;
        }
      }
    }

    a {
      display: block;
      line-height: 48px;
      width: 50px;
      border: 1px solid $border-c;
      color: #888;
      text-align: center;
      font-size: 1.25rem;

      &:hover {
        border-color: $color1-c;
        background-color: $color1-c;
        color: $white-c;
      }
    }
  }
  .brand-search-block{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $border-c;
    .flex-block{
      margin-left: 20px;
    }
    input{
      min-width: 350px;
    }
  }
  @include media-breakpoint-down(sm){
    .brand-search-block{
      flex-direction: column;
      align-items: flex-start;
      .flex-block{
        width: 100%;
        margin-top: 20px;
        margin-left: 0px;
      }
      input{
        min-width: unset;
        width: 100%;
      }
    }
  }
}

//////////////////////////////////////////////////////////
//CONTACT PAGE
//////////////////////////////////////////////////////////
.contact-page {

  .contact-data {
    background-color: $blue;
    padding: 20px;
    color: $white-c;
    h2 {
      font-size: 1.25rem;
      text-transform: uppercase;
      margin-bottom: 0;
    }


    table{
      border: 0;
      font-size: 1.125rem;
      margin-top: 20px;

      tbody {
        border-bottom: 1px solid $border-c;
      }

      td {
        border: 0;
        border-top: 1px solid $border-c;
        line-height: 26px;
        padding: 20px 0 20px 40px;
        position: relative;

        &:before{
          content:"";
          font-family: 'FontAwesome';
          position: absolute;
          left: 0;
          @include center(y);
          font-size: 1.25rem;
          width: 30px;
          display: inline-block;
          text-align: center;
        }
      }
      tr:nth-of-type(1) td:before {content: "\f041";}
      tr:nth-of-type(2) td:before {content: "\f095";}
      tr:nth-of-type(3) td:before {content: "\f0e0";}
      tr:nth-of-type(4) td:before {content: "\f017";}
      tr:nth-of-type(5) td:before {content: "\f19c";}
      
      
    }
  }

  .second-block {

    .block-title {
      font-size: 1.75rem;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 0px;
      padding-bottom: 20px;
      border-bottom: 1px solid $gray-c;
    }
  }

  .loader-holder {
    margin-top: 0;
    padding: 0;
    span{
      font-size: 1rem;
      color: $red-c;
      margin-right: 15px;
      font-weight: 700;
    }
  }

  .contact-btn-block{
    margin-top: 17px;
    display: flex;
    flex-direction: column;
    align-items: end;
  }
  @include media-breakpoint-down(md) {

    .second-block {
      margin-top: 40px;
    }
  }

  @include media-breakpoint-only(xs) {

    .contact-data {

      table{

        td {
          padding: 15px 0 15px 40px;

          &:before{
            left: 5px;
          }
        }
      }
    }
    .contact-btn-block{
      margin-top: -25px;
      margin-bottom: 20px;
    }
  }

}

//////////////////////////////////////////////////////////
//FAQ PAGE
//////////////////////////////////////////////////////////
.faq-page {

  .faq-accordion {
    clear: both;

    .ui-accordion-header-icon{
      display: none;
    }
  }

}

.faq-accordion{

  .accordion-block {

    > h3 {
      margin: 0;
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 26px;
      padding: 12px 45px 12px 0;
      position: relative;
      cursor: pointer;
      border-bottom: 1px solid $border-c;

      > span {
        position: absolute;
        right: 0; top: 10px;
        height: 30px; width: 30px;
        line-height: 30px;
        display: inline-block;
        text-align: center;
        color: $text-c;
        font-size: 1.5rem;
        @include transition-all(0.4s);
      }
    }

    &.active > h3 {
      border-bottom: none;
      color: $color1-c;

      > span {
        color: $text-c;
        @include transform(rotate(180deg));
      }
    }

    > div {
      padding: 15px 0;
    }
  }
}

//////////////////////////////////////////////////////////
// To top
//////////////////////////////////////////////////////////
#to-top {
  display: inline-block;
  background-color: $color1-c;
  width: 40px;
  height: 40px;
  text-align: center;
  position: fixed;
  bottom: 20px;
  right: 50px;
  transition: background-color .3s, opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  &:after {
    content: "\f106";
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    font-size: 1.875rem;
    line-height: 35px;
    color: $black-c;
  }
  &:hover {
    cursor: pointer;
    background-color: $gray-c;
  }
  &:active {
    background-color: $gray-c;
  }
  &.show {
    opacity: 1;
    visibility: visible;
  }
  @include media-breakpoint-down(xs){
    right: 20px;
  }
}
.icon-block{
  padding-top: 40px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  @include media-breakpoint-down(md){
    grid-template-columns: repeat(4, 1fr);
  }
  @include media-breakpoint-down(xs){
    grid-template-columns: repeat(3, 1fr);
  }
}
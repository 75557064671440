//BLOG PREVIEW (HOMEPAGE
.blog-preview-section {
  padding: 60px 0;

  .blog-preview-item {
    position: relative;
    border: 1px solid $border-c;
    background-color: $white-c;
  }

  .image-block {
    position: relative;
    overflow: hidden;
  }

  .image-wrapper {
    overflow: hidden;
    img {
      position: absolute;

    }
  }

  .image-block:hover {
    .about {
      background-color: $color1-c;
      color: $white-c;
      .date {
        &:before {
          background-color: $white-c;
        }
      }
    }
  }

  .text-block {
    padding: 20px;
    .about {
      border-bottom: 1px solid $border-c;
      padding-bottom: 10px;
    }
    h3 {
      font-size: 1.25rem;
      font-weight: 700;
      text-transform: uppercase;
      padding: 1rem 0;
      margin-bottom: 1rem;
      border-bottom: 1px solid $border-c;
    }
    p {
      font-size: 0.875rem;
      margin: 0;
    }
    .short-text{
      margin: 0;
      min-height: 88px;
      display: block;
    }
  }

  @include media-breakpoint-down(md){
    padding-top: 0;
    padding-bottom: 0;
  }
  @include media-breakpoint-only(xs) {
    .blog-preview-item {
      margin-bottom: 30px;
    }
    .image-block {
      margin-bottom: 35px;
    }
  }
  @include media-breakpoint-down(sm) {
    .about {
      padding: 5px 0;
      .date {
        padding-bottom: 5px;
        margin-bottom: 5px;
      }
    }
  }
}

//BLOG LIST PAGE
.blog-list-page {
  .blog-list{
    display: inline-block;
    .col-lg-4:nth-of-type(3n+1) {
      clear: both;
    }
    @include media-breakpoint-down(md){
      .col-lg-4:nth-of-type(3n+1) {
        clear: unset;
      }
      .col-md-6:nth-of-type(2n+1) {
        clear: both;
      }
    }
  }

  .blog-post-preview {
    position: relative;
    margin-bottom: 30px;
    border: 1px solid $border-c;
    background-color: $white-c;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }

    .image-block {
      position: relative;
    }

    .image-block:hover {
      .about {
        background-color: $color1-c;
        color: $white-c;
        .date {
          &:before {
            background-color: $white-c;
          }
        }
      }
    }

    .author-categories {
      font-size: 0.75rem;

      span {
        display: inline-block;
      }

      .author .title {
        color: #bababa;
      }

      .divider {
        color: #bababa;
        padding: 0 5px;
      }
    }

    .text-block {
      padding: 20px;
      .about {
        border-bottom: 1px solid $border-c;
        padding-bottom: 10px;
        //width: 115px;
        //border: 2px solid $color1-c;
        //background-color: $white-c;
        //font-weight: 700;
        //text-align: center;
        //padding: 10px 0;
        //text-transform: uppercase;
        //position: absolute;
        //left: 25px; bottom: -25px;
        @include transition-all(0.4s);
        //.date {
        //  display: block;
        //  font-size: 12px;
        //  position: relative;
        //  padding-bottom: 10px;
        //  margin-bottom: 10px;
        //  &:before {
        //    content: '';
        //    position: absolute;
        //    left: 50%; bottom: 0;
        //    @include translate(-50%, 0);
        //    width: 30px; height: 1px;
        //    background-color: $border-c;
        //  }
        //}
        //.comments-qty {
        //  display: block;
        //  font-size: 10px;
        //}
      }
      h3 {
        font-size: 1.25rem;
        font-weight: 700;
        text-transform: uppercase;
        padding: 1rem 0;
        margin-bottom: 1rem;
        border-bottom: 1px solid $border-c;
      }
      p {
        font-size: 0.875rem;
        margin: 0;
      }
      .short-text{
        margin: 0;
        min-height: 88px;
        display: block;
      }
    }


    @include media-breakpoint-down(md) {
      .blog-preview-item {
        margin-bottom: 30px;
      }
      .image-block {
        margin-bottom: 35px;
      }
    }
    @include media-breakpoint-down(sm) {
      .about {
        padding: 5px 0;
        .date {
          padding-bottom: 5px;
          margin-bottom: 5px;
        }
      }
    }

  }
}

//BLOG LIST PAGE
.single-blog-page {

  .blog-post {
    position: relative;
//    border-bottom: 1px solid $border-c;
//    margin-bottom: 60px;
//    padding-bottom: 40px;

    .image-block {
      position: relative;
      margin-bottom: 60px;
    }

    .about {
      width: 115px;
      border: 2px solid $color1-c;
      background-color: $white-c;
      font-weight: 700;
      text-align: center;
      padding: 10px 0;
      text-transform: uppercase;
      position: absolute;
      left: 25px; bottom: -25px;
      @include transition-all(0.4s);
      .date {
        display: block;
        font-size: 12px;
        position: relative;
        padding-bottom: 10px;
        margin-bottom: 10px;
        &:before {
          content: '';
          position: absolute;
          left: 50%; bottom: 0;
          @include translate(-50%, 0);
          width: 30px; height: 1px;
          background-color: $border-c;
        }
      }
      .comments-qty {
        display: block;
        font-size: 10px;
      }
    }

    .author-categories {
      font-size: 0.75rem;
      padding: 1rem 0;

      span {
        display: inline-block;
      }

      .author .title {
        color: #bababa;
      }

      .divider {
        color: #bababa;
        padding: 0 5px;
      }
    }

    .text-block {

      h1.blog-title {
        font-size: 1.25rem;
        font-weight: 700;
        text-transform: uppercase;
        padding: 1rem 0;
        margin-bottom: 1rem;
        border-bottom: 1px solid $border-c;
      }
    }
  }

  .social-links-holder {
    display: flex;
    align-items: center;
    padding: 20px 0;
    margin-top: 20px;
    border-top: 1px solid $gray-c;
    border-bottom: 1px solid $gray-c;
    .block-title {
      font-size: 1rem;
      margin-bottom: 0;
      margin-right: 20px;
      display: block;
      color: #202020;
    }

    .social-links {
      display: block;
      @extend .clearfix;

      li {
        display: inline-block;
        float: left;

        &:not(:first-of-type) {
          a {
            border-left: 0;
          }
        }
      }

      a {
        display: block;
        line-height: 38px;
        width: 40px;
        border: 1px solid $border-c;
        color: #888;
        text-align: center;
        font-size: 1rem;
        color: $blue;

        &:hover {
          border-color: $blue;
          background-color: $blue;
          color: $white-c;
        }
      }
    }
  }

  .related-blog{
    margin-top: 20px;
    .category-title{
      background-color: $blue;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.25rem;
      padding: 14px 20px;
      margin-bottom: 20px;
      color: $white-c;
    }
    .blog-post-preview {
      .image-block{
        margin-bottom: 0;
      }
    }
    .text-block {
      h3 {
        font-size: 1.25rem;
        font-weight: 600;
        margin: 20px 0 5px 0;
        &:hover{
          color: $color1-c;
        }
      }
      p {
        font-size: 1rem;
        min-height: 75px;
      }
    }
  }

  .comments-block {

    .block-title {
      text-transform: uppercase;
      font-size: 1.25rem;
      font-weight: 700;
    }

  }

  .recommended-products {
    .block-title{
      text-align: center;
      background-color: $blue;
      color: $white-c;
      padding: 6px;
    }
    .product-preview-item{
      margin-bottom: 35px;
    }
    @include media-breakpoint-down(md) {
      margin-top: 40px;
    }
  }

  @include media-breakpoint-down(xs){
    .social-links-holder{
      align-items: flex-start;
      flex-direction: column;
      .block-title{
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}
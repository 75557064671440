/* product_quick_view */
#product_quick_view_div, #cart_popup_product_view_div, #order_again_view_div{
  position:fixed;
  top:0; left: 0;
  width:100%; height:100%;
  background:rgba(0,0,0,0.7);
  z-index:9999;
  .product-name{
    margin-bottom: 20px;
  }
}
#order_again_view_div{
  .block-title {
    font-size: 1.25rem;
    margin-bottom: 10px;
    color: $black-c;
  }
  .table-container{
    max-height: 400px;
    overflow: auto;
    @include media-breakpoint-down(xs){
      max-height: 300px;
    }
  }
  .shopping-table {
    .product-info {
      height: 100px;
      .image-block {
        position: absolute;
        left: 35px;
        top: 10px;
        width: 75px;
        height: 75px;
      }
      .text-block {
        padding-left: 100px;
      }
    }
    @include media-breakpoint-down(sm){
      .product-info {
        .image-block {
          left: 100px;
        }
        .text-block {
          padding-top: 0;
          padding-left: 80px;
        }
      }
    }
    @include media-breakpoint-down(xs){
      .product-info {
        .image-block {
          left: 90px;
          width: 60px;
        }
        .text-block {
          padding-left: 60px;
        }
      }
    }
  }
  .block-btn{
    display: flex;
    justify-content: center;
    margin: 10px 0 0 0;
    .large-button{
      font-size: 1.5rem;
      color: $white-c;
      background-color: $black-c;
      text-align: center;
      line-height: 28px;
      display: block;
      //width: 100%;
      padding: 10px;
      border: 1px solid $gray-c;
      cursor: pointer;
      @include transition-all(0.4s);
      min-width: 316px;
      &:hover{
        background-color: $color1-c;
        color: $white-c;
      }
      @include media-breakpoint-down(sm){
        min-width: auto;
        font-size: 1rem;
      }
    }
  }

}
.pqv-inner-div {
  position:absolute;
  left:50%; top:50%;
  width:900px; height:auto;
  max-width: 90%;
  background: $white-c;
  padding:10px;
  @include center(xy);

  .close{
    position:absolute;
    right: -17px; top: -17px;
    width:45px; height:45px;
    text-indent:-99999px;
    opacity: 0.85;
    background: url("../../assets/images/close-quick.png") ;
    &:hover {
      :hover{opacity: 1}
    }
  }
}

.pqv-thumb .frame{padding: 1px; border: 1px solid $gray-c;}
.pqv-thumb .frame>div{
  position: relative;
}
.pqv-inner-div .pqv-thumb img:not(.prop-holder){
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

#cart_popup_product_view_div .title-box {
  margin-bottom: 20px;

  h1{
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2rem;
    margin-bottom: 15px;
  }

  p{
    font-size: 0.9375rem;
    margin-bottom: 15px;
  }
}

@include media-breakpoint-only(xs) {
  #product_quick_view_div{
    .product-options{
      margin-top: 15px;

      .product-cart{
        padding: 1.25rem 0 0;
        border-bottom: 0;
      }
      .product-header h2{
        font-size: 1.25rem;
      }
      .price-block .price{
        font-size: 1.25rem;
      }
      .price-block .old-price {
        font-size: .75rem;
      }
      .product-header .description{
        padding: 0 0 10px;

        p{
          font-size: .875rem;
        }
      }
      .counter-holder .large-button{
        line-height: 120%;
        border: none;
      }

      .product-cart .product-links-holder ul li:last-child{
        display: none;
      }
    }
  }
}
.registration-block {

  .note {
    margin-bottom: 10px;

    .c-important {
      color: $color1-c;
    }

    .fa {
      color: $color1-c;
      position: relative;
      top: -5px;
    }
  }

  .personal-data-title {
    font-size: 1.25rem;
    line-height: 20px;
    padding: 10px 0;
    margin-top: 20px;
    text-transform: uppercase;
    font-weight: 400;
    color: $color1-c;
  }

  .user-type {
    > div:first-of-type {
      margin-left: 30px;
    }
  }

  .second-block {
    margin-top: 30px;

    @include media-breakpoint-up(lg) {
      position: static;

      .bottom-block {
        position: absolute;
        bottom: 0; right: 15px;
      }
    }
    @include media-breakpoint-down(md){
      margin-top: 0;
    }
  }

}

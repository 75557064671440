.price-range-holder{
  padding: 0 6px;
  margin-top: 20px;

  .slider-range{
    background: $white-c;
    border: 1px solid rgba(32, 32, 32, 0.1);
    border-radius: 5px;
    height: 7px;
    margin-bottom: 10px;
    width: 100%!important;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;

    & .ui-slider-range{
      background: rgba(32, 32, 32, 0.1);
      border: 0 none;
      height: 7px;
      border-radius: 0;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
    }

    & .ui-state-focus,
    & .ui-state-hover,
    & .ui-state-active,
    & .ui-slider-handle{
      width: 20px; height: 20px;
      border: 3px solid $red-c;
      background: $red-c;
      border-radius: 50%;
      cursor: pointer;
      margin-left: -6px;
      top: -7px;
    }
  }

  .slider-input {
    border: 0;
    padding: 5px 0;
    font-size: 1.125rem;
    text-align: center;
    color: rgba(32, 32, 32, 0.7);
  }

  .ui-slider .ui-slider-handle {
    z-index: 1;
  }
}
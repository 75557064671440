#header {
  position: relative;
  z-index: 2;

  section.top {
    background-color: $blue;
    color: $white-c;
    position: relative;

    .contact {
      font-size: 0.75rem;
      display: inline-block;
      padding-top: 10px;
      > div {
        display: inline-block;
        &:first-of-type {
          margin-right: 30px;
        }
      }
      .icon {
        display: inline-block;
        width: 30px; height: 30px;
        margin-right: 10px;
        vertical-align: middle;
        text-align: center;
        line-height: 28px;
        border: 1px solid $white-c;
      }
      .darker {
        color: #CBCBCB;
      }
      .icon-phone {
        display: none;
      }
      @include media-breakpoint-only(sm) {
        > div {
          display: block;
          line-height: 14px;

          .icon {
            display: none;
          }
        }
      }

      @include media-breakpoint-only(xs) {
        display: none;
      }
    }

    .options {
      float: right;
      display: inline-block;
      > div {
        display: inline-block;
        float: left;
        border-left: 1px solid $white-c;
        &:last-of-type {
          border-right: 1px solid $white-c;
        }
        .c-drop-trigger {
          display: block;
          line-height: 20px;
          padding: 15px;
        }
      }
    }
  }

  section.main {

    .c-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px 0;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .logo {
      display: inline-block;
    }

    .search {
      width: 320px;
      display: inline-block;
      form {
        display: flex;
      }
      button {
        padding-left: 0;
        padding-right: 0;
        flex: 0 0 40px;
        background-color: $blue;
        border-color: $blue;
        &:hover{
          background-color: $white-c;
          .fa{
            color: $blue;
          }
        }
      }
    }
    @include media-breakpoint-down(md) {

      .logo {
        height: 40px;

        p {
          height: 100%;
        }

        img {
          max-height: 100%;
          width: auto;
        }
      }

      .search {
        flex: 1; order: 1;
        width: 100%;
        flex-basis: 100%;
        margin-top: 15px;
      }
    }
    @include media-breakpoint-down(xs) {
      .c-flex{
        padding: 15px 0;
      }
      .logo {
        flex-basis: 100%;
        text-align: center;
      }
      .search {
        display: none;
      }
      .mobile-padding{
        padding: 0;
      }
    }
  }
}

.languages {

  .c-drop-content {
    width: auto!important;
  }

  .content-holder{
    padding: 10px 20px;
    top: 45px;
    text-align: left;
    border: 3px solid $color2-c;
    background-color: $white-c;
  }

  li{

    a{
      font-weight: 600;
      padding-top: 5px;
      font-size: 0.875rem;
      text-transform: none;
      color: $color2-c;
      position: relative;
      display: block;
      text-align: right;
    }

    &:hover a{
      color: $color1-c;
    }
  }
}

.user-logged{

  .c-drop-content {
    width: 200px!important;
  }

  .content-holder{
    padding: 20px;
    top: 45px;
    text-align: left;
    border: 3px solid $color2-c;
    background-color: $white-c;
  }

  li{

    a{
      padding-left: 14px;
      font-weight: 600;
      padding-top: 5px;
      font-size: 0.875rem;
      text-transform: none;
      color: $color2-c;
      position: relative;
      display: block;
      &:before{
        content: "\f105";
        position: absolute;
        left: 3px; top: 6px;
        font-family: FontAwesome;
      }
    }

    &:hover a{
      color: $color1-c;
    }
  }
}

.account {

  .content-holder{
    padding: 20px;
    top: 45px;
    text-align: left;
    border: 3px solid $color2-c;
    background-color: $white-c;

    &.logged{
      min-width: 1px;
      padding: 10px;
      top: 45px;

      li{

        a{
          padding-left: 14px;
          font-weight: 600;
          padding-top: 5px;
          font-size: 0.875rem;
          text-transform: none;
          color: $color2-c;
          position: relative;
          display: block;
          &:before{
            content: "\f105";
            position: absolute;
            left: 3px; top: 6px;
            font-family: FontAwesome;
          }
        }

        &:hover a{
          color:$color1-c;
        }
      }
    }
  }

  .heading{
    position: relative;
    padding: 0 0 10px 0;
    color: $color2-c;
    margin: 0;
    text-transform: uppercase;
    font-weight: 600;

    &:before{
      position: absolute; left: 0; bottom: 0;
      content: "";
      width: 70px; height: 2px;
      background-color: $color2-c;
    }
  }

  .login-form-holder {
    @extend .clearfix;

    .input-holder{
      border: 1px solid #ddd;
      padding: 2px 2px 2px 31px;
      position: relative;
      margin-top: 20px;

      input{
        margin-top: 0;
        border: 0;
        height: 30px;
        padding: 5px 15px;
        color: $color2-c;
      }
      span{
        background-color: $color2-c;
        display: inline-block;
        width: 29px;
        height: 29px;
        color: #fff;
        position: absolute;
        left: 2px;
        top: 2px;
        text-align: center;
        line-height: 29px;
        z-index: 1;
        &:after{
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 0 5px 6px;
          border-color: transparent transparent transparent $color2-c;
          position: absolute;
          top: 50%;
          margin-top: -5px;
          left: 100%;
        }
      }
    }
  }

  .forgotten-password,
  .register-link {
    line-height: 20px;
    display: block;
    padding-left: 5px;
    color: $color1-c;
    text-decoration: underline;

    &:hover {
      color: $color1-c;
    }
  }

  .forgotten-password {
    color: $color2-c;
    margin-top: 20px;
  }
}

.mobile-nav{
  display: none;
  .menu-block {
    width: 40px;
    height: 40px;
    background-color: $color1-c;
    padding: 5px;
  }
  .responsive-block {
    display: none;
    width: 40px;
    position: absolute;
    top: 131px;
    z-index: 13;
    right: 110px;
    .phone{
      display: none;
    }
    .icon-phone {
      width: 40px;
      height: 40px;
      text-align: center;
      background-color: $color1-c;
      display: block;
      border: 2px solid $color1-c;
      .fa{
        color: $white-c;
        font-size: 1.75rem;
        line-height: 36px;
        width: 100%;
      }
      &:hover{
        background-color: $white-c;
        .fa{
          background-color: $white-c;
          color: $color1-c;
        }
      }
    }
  }
  .fix-search{
    display: none;
    .search{
      display: block !important;
      right: 0;
      width: 100%;
      position: absolute;
      margin: 0 !important;
      height: 40px;
      .search-preview {
        float: right;
        width: 40px;
        height: 40px;
        margin-top: -40px;
        margin-right: 60px;
        .icon {
          display: inline-block;
          width: 40px; height: 40px;
          padding-left: 0;
          padding-right: 0;
          font-size: 1.5rem;
        }
      }
    }
    .c-drop-holder.drop-open .c-drop-content {
      top: 10px !important;
    }
  }
  @include media-breakpoint-only(md){
    .fix-search{
      .search{
        .search-preview {
          margin-right: 60px;
        }
      }
    }
  }
  @include media-breakpoint-only(sm){
    position: relative;
    .fix-search{
      .search{
        .search-preview{
          .icon{
            padding: 5px 0;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(md){
    display: block;
  }
  @include media-breakpoint-down(xs){
    .responsive-block {
      display: block;
    }
    .fix-search{
      display: block;
    }
  }
}
.fixed-header {
  .mobile-nav {
    .responsive-block {
      top: 10px;
      @include media-breakpoint-only(sm){
        top: 0;
      }
    }
  }
  @include media-breakpoint-down(sm){
    .responsive-block {
      display: block;
    }
    .fix-search{
      display: block;
    }
  }
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.fixed-header {
  padding-top: 67px;
  @include media-breakpoint-down(md) {
    padding-top: 52px;
  }
  .navigation {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    -webkit-animation-duration: 600ms;
    animation-duration: 600ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    z-index: 10;
    background-color: $white-c;
  }
}




//default style
.c-drop-holder {
  position: relative;
  .c-drop-content {
    position: absolute;
    right: 0; top: 110%;
    width: 360px;
    opacity: 0; visibility: hidden;
    z-index: -1;
    @include transition(opacity 0.4s, top 0.4s);
  }
  .c-drop-trigger {
    @extend .noselect;
    cursor: pointer;
  }

  &.drop-open {
    .c-drop-content {
      top: 100%;
      opacity: 1; visibility: visible;
      z-index: 14;
    }
  }

  @include media-breakpoint-down(xs) {
    position: static;
    .c-drop-content {
      width: 100%!important;
    }
  }
}

.c-drop-holder {
  .c-drop-content {

  }
  .c-drop-trigger {
    font-size: 0.75rem;
  }
}
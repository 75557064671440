.c-banner {
  position: relative;
  margin: 0 auto;
  max-width: 1000px;
  list-style: none;
  text-align: center;
}

/* Common style */
.c-banner figure {
  position: relative;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  margin: 0;
}

.c-banner figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
  -webkit-backface-visibility: hidden;
}

.c-banner figure figcaption {
  padding: 15px;
  color: $white-c;
  text-transform: uppercase;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.c-banner figure figcaption::before,
.c-banner figure figcaption::after {
  pointer-events: none;
}

.c-banner figure figcaption,
.c-banner figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.c-banner figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
  -webkit-backface-visibility: hidden;
}

.c-banner figure h2 {
  word-spacing: -0.15rem;
  font-weight: 400;
}

.c-banner figure h2 span {
  font-weight: 700;
}

.c-banner figure h2,
.c-banner figure p {
  margin: 0;
}

.c-banner figure p {
  letter-spacing: 1px;
  font-size: 68.5%;
}

/* Individual effects */

/*---------------*/
/***** Bubba *****/
/*---------------*/

figure.effect-bubba {
  background: $black-c;

  img {
    opacity: 0.7;
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
  }

  figcaption::before,
  figcaption::after {
    position: absolute;
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    content: '';
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
  }

  figcaption::before {
    border-top: 1px solid $white-c;
    border-bottom: 1px solid $white-c;
    -webkit-transform: scale(0,1);
    transform: scale(0,1);
  }

  figcaption::after {
    border-right: 1px solid $white-c;
    border-left: 1px solid $white-c;
    -webkit-transform: scale(1,0);
    transform: scale(1,0);
  }

  h2 {
    padding-top: 30%;
    font-size: 2.25rem;
    font-weight: 700;
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    -webkit-transform: translate3d(0,-20px,0);
    transform: translate3d(0,-20px,0);
  }

  p {
    padding: 20px;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(0,20px,0);
    transform: translate3d(0,20px,0);
  }

  &:hover {
    figcaption::before,
    figcaption::after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    img{
      opacity: 0.4;
    }

    h2,
    p {
      opacity: 1;
      -webkit-transform: translate3d(0,0,0);
      transform: translate3d(0,0,0);
    }
  }

  @include media-breakpoint-down(md) {

    h2 {
      font-size: 24px;
    }

    p {
      padding: 10px;
    }
  }
}
//////////////////////////////////////////////////////////
//USER ACCOUNT
//////////////////////////////////////////////////////////
.user-account {

  .block-title {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    color: $color1-c;
  }

  .user-data {

    label {
      @extend .c-input-label;
    }

    input {
      @extend .c-input;
    }
  }

  .second-block {

    .note {

      .c-important {
        color: $color1-c;
      }
    }
    &.new-password{
      padding: 10px;
      border: 1px solid $color1-c;
      border-radius: 10px;
      margin: 20px 0 30px 0;
      .subtitle-pass{
        font-size: 1.5rem;
        margin-bottom: 0;
      }
      .pass-note{
        font-size: 0.875rem;
        font-style: italic;
        margin: 20px 0 0 0;
      }
      @include media-breakpoint-down(sm){
        margin: 20px 0 10px 0;
      }
    }
  }
}

//////////////////////////////////////////////////////////
//USER WISHES
//////////////////////////////////////////////////////////
.user-wishes {

  .block-title {
    font-size: 1.25rem;
    margin-bottom: 40px;
    color: $color1-c;
    text-transform: uppercase;
  }

  .empty-wishlist {
    margin-bottom: 40px;
  }

  .products-wishlist {

    .product-preview-item {
      margin-bottom: 30px;
    }

    @include media-breakpoint-down(sm)  { > div:nth-of-type(2n+1) {clear: both}}
    @include media-breakpoint-only(md)  { > div:nth-of-type(3n+1) {clear: both}}
    @include media-breakpoint-up(lg)    { > div:nth-of-type(4n+1) {clear: both}}
  }

  .wishes-instruction{
    padding: 0 0 60px 0;
    clear: both;
    margin-top: 10px;

    h3{
      font-size: 1.25rem;
      padding: 0 0 12px 0;
    }

    > p{
      max-width: 555px; width: 100%;
      position: relative;
      line-height: 26px;
      padding: 6px 60px 6px 0;
      &.full-width{
        max-width: 100%;
      }

      span{
        color: $white-c;
        background-color: $color2-c;
        display: inline-block;
        width: 38px; height: 38px;
        line-height: 38px;
        font-size: 1.125rem;
        text-align: center;
        position: absolute;
        right: 0; top: 50%;
        @include center(y);
      }

      &:last-of-type {
        span {
          background-color: $color2-c;
        }
      }

    }
  }

}

//////////////////////////////////////////////////////////
//USER ORDERS
//////////////////////////////////////////////////////////
.user-orders {

  .block-title {
    font-size: 1.25rem;
    margin-bottom: 40px;
    color: $color1-c;
    text-transform: uppercase;
  }

  .single-order {
    margin-bottom: 40px;
    .shopping-table {
      .product-info {
        height: 100px;
        .image-block {
          position: absolute;
          left: 35px;
          top: 10px;
          width: 75px;
          height: 75px;
        }
        .text-block {
          padding-left: 100px;
        }
      }
      @include media-breakpoint-down(sm){
        .product-info {
          .image-block {
            left: 100px;
          }
          .text-block {
            padding-top: 0;
            padding-left: 80px;
          }
        }
      }
      @include media-breakpoint-down(xs){
        .product-info {
          .image-block {
            left: 90px;
            width: 60px;
          }
          .text-block {
            padding-left: 60px;
          }
        }
      }
    }
  }

  .shopping-table .product-qty input {
    border: none;
  }

  //SINGLE ORDER TOTAL (DATE, STATUS, PRICE)
  .single-order-total{
    margin-top: 20px;

    .order-date{
      font-size: 1rem;
      text-transform: uppercase;
      text-align: center;
      //margin: 17px 0 0 0;
      @media(min-width: 992px) and (max-width: 1199px){
        margin: 3px 0 0 0;
      }
    }

    .order-status{
      font-size: 1rem;
      color: $white-c;
      text-transform: uppercase;
      text-align: center;
      line-height: 50px;
      padding-left: 20px;
      float: left;

      &.status1{background-color: $color1-c; }
      &.status2{background-color: #FFC73C;}
      &.status3{background-color: #02CC8B;}

      span{
        border-left: 1px solid $white-c;
        padding: 0 20px;
        display: inline-block;
        margin-left: 10px;}
    }

    .order-price {

      .text{
        line-height: 50px;
        font-size: 1.25rem;
        color: $color1-c;
        text-transform: uppercase;
        text-align: right;
      }
    }

    .order_again {
      padding: 13px 5px;
      text-transform: uppercase;
      @include media-breakpoint-down(md){
        padding: 10px;
        width: 100%;
      }
    }

    @include media-breakpoint-down(md) {

      .order-date,
      .order-status{
        display: block;
        float: none;
        margin: 0 0 10px 0;
        padding: 0;
        width: 100%;
        font-size: 1rem;
        text-align: center;
      }

      .order-date span,
      .order-status span{
        display: inline-block;
        //float: right;
        //width: 38%;
        //padding: 0;
        //margin-left: 0;
        text-align: center;
      }


    }
    @include media-breakpoint-down(sm) {
      .order-date{
        //line-height: 50px
      }

      .order-status{
        line-height: 30px
      }

      .order-price {

        .text{
          text-align: center;
          line-height: 30px;
        }
      }
    }
  }

}
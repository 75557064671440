footer {
  background-color: $blue;
  color: $white-c;

  .top {
    padding: 50px 0;
  }

  /* Footer Intesa */
  .middle-block{background: #FFF; padding: 20px 0;}
  .middle-block .right{text-align: right;}
  .middle-block img{max-height: 42px;	width: auto !important; margin: 0 7px;}
  .middle-block .col-md-5{ width: 37%;}
  .middle-block .col-md-2{width: 19%;}
  .middle-block .col-md-5:last-child{width: 43%}
  @media (max-width: 992px){
    .middle-block .col-md-5, .middle-block .col-md-2{ width: 100% !important; text-align: center !important; padding:10px 0;}
    .middle-block .right { text-align: center;	}
    .middle-block  .foo-links{margin-bottom: 15px;}
    .middle-block  .col-md-12:last-child .foo-links{margin-bottom: 0;}
  }

  .social-link {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
    p{
      margin: 0;
      font-size: 16px;
      font-weight: 600;
    }
    .fa{
      font-size: 20px;
      color: $white-c;
    }
  }
  .read-more {
    float: right;
    @extend .clearfix;
    text-decoration: underline;
    &:hover {
      color: $color1-c;
      text-decoration: underline;
    }
  }

  img {
    margin-bottom: 15px;
  }

  .foo-block {
    margin-top: 20px;
    margin-bottom: 20px;
    h3 {
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 700;
      padding-bottom: 15px;
      margin-bottom: 30px;
      border-bottom: 1px solid $border-c;
    }
    ul {
      li {
        display: block;
        margin-bottom: 5px;
      }
      a {
        display: inline-block;
        position: relative;
        padding-left: 15px;
        font-size: 0.75rem;
        color: $white-c;
        &:before {
          content: "\f105";
          font-family: FontAwesome;
          color: $white-c;
          font-size: 14px;
          position: absolute;
          left: 0; top: 50%;
          @include translate(0, -50%);
          @include transition-all(0.2s);
        }
        &:hover {
          &:before {
            left: 3px;
          }
        }
      }
    }
    table {
      margin-top: -10px;
    }
    tbody > tr{

      td {
        padding-left: 40px;
        position: relative;
        font-size: 0.75rem;
        padding-top: 10px;
        padding-bottom: 10px;
        &:before {
          position: absolute;
          left: 10px; top: 50%;
          @include translate(0, -50%);
          font-size: 1rem;
          font-family: FontAwesome;
        }
      }

      &:nth-of-type(1) td:before {
        content: "\f041";
      }
      &:nth-of-type(2) td:before {
        content: "\f095";
      }
      &:nth-of-type(3) td:before {
        content: "\f0e0";
      }
      &:nth-of-type(4) td:before {
        content: "\f017";
      }
    }
  }

  .bottom {
    padding: 30px 0;
    background-color: $blue;
  }

  .copyright {
    display: block;
    color: $gray-c;
    font-size: 0.75rem;
    font-weight: 300;
    .fa {
      color: $color1-c;
    }
    a {
      color: $white-c;
      font-weight: 400;
    }
  }
}
.banners-below-slider {
  // padding: 40px 0;
  // @include media-breakpoint-down(md){
  //   padding: 20px 0;
  // }
}

/* DISCOUNTS */
.banner-discount {
  padding: 70px 0;
  background-color: #f4f4f4;
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .c-flex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .image-block{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    img{
      width: 100%;
    }
  }

  .text-block {
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    
    > span {
      display: block;
    }

  }

  .text1 {
    font-size: 1.125rem;
  }
  .text2 {
    font-size: 2.625rem;
    color: $color1-c;
  }
  .text3 {
    font-size: 2.625rem;
    display: block;
    text-align: right;
    span {
      display: inline-block;
      position: relative;
      padding-left: 30px;
      &:before {
        content: "\f061";
        font-family: FontAwesome;
        position: absolute;
        left: -12px; 
        top: -40px;
        font-size: 30px;
      }
    }
    p {
      margin-bottom: 0;
      display: inline-block;
    }
  }
  .link {
    margin-top: 20px;
    float: right;
  }
  @include media-breakpoint-down(xs){
    .text2{
      font-size: 1.75rem;
    }
    .text3{
      font-size: 1.75rem;
      &:before {
        left: 0;
        top: -26px;
        font-size: 20px;
      }
    }
  }
}
.banner-div{
  background: #eee;
  padding: 80px 0;
  margin-top: 70px;
  position: relative;

  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: calc(50% - 110px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .image-block{
    display: none;
  }
  .text-block{
    .title-p2 {
      font-size: 2.25rem;
      font-weight: 900;
      color: $black-c;
      text-transform: uppercase;
      position: relative;
      padding: 0 0 50px;
      &:after {
        content: "";
        height: 1px;
        width: 100px;
        background: #ddd;
        font-size: 1px;
        position: absolute;
        left: 0;
        bottom: 29px;
      }
    }
    .link{
      margin-top: 20px;
    }
  }
  @include media-breakpoint-down(md){
    padding: 20px 0;
    margin-bottom: 20px;
    margin-top: 0;
    &:before{
      display: none;
    }
    .image-block{
      display: block;
      text-align: center;
      margin-bottom: 20px;
    }
    .text-block {
      .title-p2{
        margin: 0;
        padding: 0 0 20px;
        &:after{
          bottom: 10px;
        }
      }
    }
  }
}
.categories-banner{
  padding: 40px 0;
  &.p-t-0{
    padding-top: 0;
  }
  .categories-slider{
    margin-top: 40px;
    .slick-slide {
    margin: 0 10px;
    }
    .slick-list {
        margin: 0 -10px;
    }
    .text{
      text-align: center;
      .name{
        font-size: 1.5rem;
        font-weight: 700;
      }
    }
  }
  @include media-breakpoint-down(lg){
    .categories-slider {
      .text {
        .name{
          font-size: 1.25rem;
        }
      }
    }
  }
  @include media-breakpoint-down(md){
    .categories-slider{
      .image-block{
        img{
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
}
.navigation {
  border-top: 1px solid $gray-c;
  border-bottom: 1px solid $gray-c;
  padding: 10px 0;

  nav > ul {
    margin: 0;

    li {
      list-style: none;
      
    }

    a {
      display: block;

      img{
        max-width: 30px;
        margin-right: 10px;
      }
    }

    > li {
      display: inline-block;
      float: left;
      //margin-right: 10px;
      position: relative;
      @include media-breakpoint-only(lg) {
        //margin-right: 5px;
      }
      
      > span:after{
        position: absolute;
        right: 0;
        content: "\f078";
        font-size: 12px;
        font-family: 'FontAwesome';
        color: #666;
        top: 50%;
        transform: translateY(-50%);
        @include transition-all(0.4s);
      }

      > a {
        padding: 10px 17px 10px 10px;
        line-height: 20px;
        color: $text-c;
        font-size: 1rem;
        text-transform: uppercase;
        > span {
          color: $color1-c;
        }
      }
      > ul {
        position: absolute;
        left: 0; top: 110%;
        opacity: 0; visibility: hidden;
        z-index: -1;
        white-space: nowrap;
        min-width: 160px;
        @include transition(opacity 0.4s, top 0.4s);
      }

      &:hover {
        > a {
          color: $color1-c;
        }
        > ul {
          top: 100%;
          opacity: 1; visibility: visible;
          z-index: 1;
          background-color: $color2-c;
          padding: 10px;

          &:before {
            content: '';
            display: inline-block;
            border-bottom: 6px solid $color2-c;
            border-left: 5px solid rgba(0, 0, 0, 0);
            border-right: 5px solid rgba(0, 0, 0, 0);
            position: absolute;
            bottom: 100%; left: 20px;
          }

          a {
            color: $white-c;

            &:hover {
              color: $color1-c;
            }
          }
        }
      }
    }

    ul > li {
      position: relative;
      ul {
        background-color: $color2-c;
        padding: 10px;
        position: absolute;
        left: 100%; top: 0;
        margin-left: 26px;
        margin-top: -10px;
        opacity: 0; visibility: hidden;
        z-index: -1;
        @include transition(opacity 0.4s, margin-left 0.4s);

        &:before {
          content: '';
          display: inline-block;
          border-right: 6px solid $color2-c;
          border-top: 5px solid rgba(0, 0, 0, 0);
          border-bottom: 5px solid rgba(0, 0, 0, 0);
          position: absolute;
          top: 17px; left: -6px;
        }

        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          left: -16px; top: 0;
          width: 16px; height: 100%;
        }
      }

      &:hover {
        ul {
          margin-left: 16px;
          opacity: 1; visibility: visible;
          z-index: 1;
        }
      }

    }
    .categories-menu{
      display: inline-block;
      float: left;
      position: relative;
      li{
        display: inline-block;
        float: left;
        margin-right: 10px;
        position: relative;
    
        .submenu-trigger{
          &:after {
            position: absolute;
            right: 0;
            content: "\f078";
            font-size: 12px;
            font-family: FontAwesome;
            color: #666;
            top: 50%;
            transform: translateY(-50%);
            transition: all .4s ease-in-out;
          }
        }
        a{
          padding: 10px 17px 10px 10px;
          line-height: 20px;
          color: #202020;
          font-size: 1rem;
        }
        ul{
          position: absolute;
          left: 0;
          top: 110%;
          opacity: 0;
          visibility: hidden;
          z-index: -1;
          white-space: nowrap;
          min-width: 160px;
          -webkit-transition: opacity .4s,top .4s;
          -moz-transition: opacity .4s,top .4s;
          -ms-transition: opacity .4s,top .4s;
          -o-transition: opacity .4s,top .4s;
          transition: opacity .4s,top .4s;
          &:before{
            display: none;
          }
          li{
            display: block;
            width: 100%;
            margin-right: 0;
            .submenu-trigger{
              &:after {
                position: absolute;
                right: 0;
                content: "\f054";
                font-size: 12px;
                font-family: FontAwesome;
                color: $white-c;
                top: 50%;
                transform: translateY(-50%);
                transition: all .4s ease-in-out;
              }
            }
            a{
              color: $white-c;
              padding: 5px 0;
              &:hover{
                color: $red-c;
              }
            }
          }
        }
        &.active{
          >.submenu-trigger{
            &:after {
              color: $red-c;
            }
          }
          >a{
            color: $red-c;
          }
        }
        &:hover{
          > ul{
            top: 100%;
            opacity: 1;
            visibility: visible;
            z-index: 1;
            background-color: $blue;
            padding: 10px;
            margin-left: 0;
            
          }
        }
        ul > li{
          > ul{
            background-color: $color2-c;
            padding: 10px;
            position: absolute; 
            left: 100%; top: 0;
            margin-left: 26px;
            margin-top: -40px;
            opacity: 0; visibility: hidden;
            z-index: -1;
            @include transition(opacity 0.4s, margin-left 0.4s);
            &:before{
              display: block;
            }
          }
          > li{
            > ul{
              background-color: $color2-c;
              padding: 10px;
              position: absolute;
              left: 100%; top: 0;
              margin-left: 26px;
              margin-top: -40px;
              opacity: 0; visibility: hidden;
              z-index: -1;
              @include transition(opacity 0.4s, margin-left 0.4s);
            }
          }
          &:hover {
            > ul {
              margin-left: 16px;
              opacity: 1; visibility: visible;
              z-index: 1;
            }
          }
        }
      }
    }
  }
}


#responsive-menu-button {
  display: none;
  width: 30px;
  height: 30px;
  position: relative;
  @include transform(rotate(0deg));
  @include transition-all(0.25s);
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: $white-c;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    @include transform(rotate(0deg));
    @include transition-all(0.25s)
  }

  span:nth-child(1) {
    top: 3px;
  }

  span:nth-child(2),span:nth-child(3) {
    top: 13px;
  }

  span:nth-child(4) {
    top: 23px;
  }
}

.sidr-animating,
.sidr-open {
  #responsive-menu-button {

    span:nth-child(1) {
      left: 50%; top: 13px;
      width: 0%;
    }

    span:nth-child(2) {
      top: 13px;
      @include transform(rotate(45deg));
    }

    span:nth-child(3) {
      top: 13px;
      @include transform(rotate(-45deg));
    }

    span:nth-child(4) {
      left: 50%; top: 13px;
      width: 0%;
    }
  }
}


#navigation {
  display: inline-block;
}
@include media-breakpoint-down(md){
  #responsive-menu-button {
    display: block;
  }
  #navigation {
    display: none;
  }
}

#products-tabs,
#product-description-tabs{
  display: block;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 60px;
  li {
    display: inline-block;
  }
  a {
    display: block;
    padding: 8px 15px;
    border: 2px solid $gray-c;
    font-weight: 700;
    text-transform: uppercase;
    min-width: 100px;
    margin: 0 5px;
  }
  .active a {
    background-color: $color1-c;
    border-color: $color1-c;
    color: $white-c;
  }
  @include media-breakpoint-down(md){
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

#product-description-tabs{
  margin-top: 0;
  margin-bottom: 20px;
}

.product-description-tabs-section {
  background-color: rgba(32, 32, 32, 0.1);
  padding: 40px 0  40px 0;
  margin-top: 40px;
}

@include media-breakpoint-only(xs) {
  #products-tabs {
    a {
      min-width: 150px;
      margin-top: 10px;
    }
  }
}
.find-us-on-map {
  text-align: center;
  border-top: 1px solid $gray-c;

  .map-trigger-holder {
    background: rgba(32, 32, 32, 0.1);
  }

  .map-trigger {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 20px;
    text-transform: uppercase;
    color: $red-c;
    font-size: 0.9375rem;
    font-weight: 700;
    padding: 11px 0;
    .map-btn{
      border: 1px solid $red-c;
      padding: 7px 20px;
    }
    .fs{
      font-size: 1.125rem;
      margin-left: 10px;
    }
  }

  .map {
    height: 400px;
    position: relative;
    overflow: hidden;

    iframe {
      position: absolute;
      left: 0; top: 0;
      height: 100%; width: 100%;
    }
  }
}
//TOP PART (NAVIGATION
.buying-process-navigation{
  position: relative;
  padding: 0;
  background: #F3F3F3;

  a, a:hover, a:focus{
    color: $color2-c;
    text-decoration: none;
  }

  span{
    font-size: 0.9375rem;
    display: block;
    text-transform: none;
    font-weight: normal;
  }

  li{
    position: relative;
    font-size: 1.125rem;
    display: inline-block;
    width: 25%; height: 100px;
    float: left;
    padding: 20px 0 0 90px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 700;
    &:before{
      position: absolute;
      left: 35px; top: 20px;
      font-family: FontAwesome;
      font-size: 35px;
      color: $color2-c;
    }
    &:nth-child(1):before{content: "\f07a";}
    &:nth-child(2):before{content: "\f007";}
    &:nth-child(3):before{content: "\f0d1";}
    &:nth-child(4):before{content: "\f00c";}

    &.current{
      color: $white-c;
      background-color: $color1-c;
      &:before{
        color: $white-c;
      }

      a, a:hover, a:focus{
        color: $white-c;
      }
    }
  }

}

@include media-breakpoint-only(lg){
  .buying-process-navigation li{
    font-size: 18px;
    padding: 20px 0 0 70px;

    span{
      font-size: 12px;
    }
  }
}
@include media-breakpoint-only(md){
  .buying-process-navigation li{
    padding: 22px 0 0 55px;
    font-size: 14px;
    background-position: 0 7px;
    &:before{
      left: 15px;
    }

    span{
      font-size: 12px;
    }
  }
}
@include media-breakpoint-down(sm){
  .buying-process-navigation{
    ul{
      display: flex;
      border: 1px solid $gray-c;
    }
    li{
      width: 40px;
      height: 40px;
      padding: 0;
      border-right: 1px solid $gray-c;
      &:last-of-type{
        border-right: unset;
      }
      &:before{
        left: 50%;
        top: 50%;
        font-size: 18px;
        transform: translate(-50%,-50%)
      }
      a{
        display: none;
      }
      &.current{
        flex: 1;
        padding: 5px 0 5px 46px;
        line-height: 30px;
        a{
          display: block;
          span{
            display: none;
          }
        }
        &:before{
          left: 15px;
          top: 50%;
          transform: translateY(-50%)
        }
      }

      span{
        display: none;
      }
    }
  }
}

//////////////////////////////////////////////////////////
//FIRST STEP (SHOPPING CART VIEW)
//////////////////////////////////////////////////////////

//ORDER (BLOCK OF SHOPPING TABLE, ORDER TOTAL PRICE AND STATUS AND TIME OF ORDER)
.order {
  margin-bottom: 40px;
}

.empty-cart {
  @extend .clearfix;
  color: $color1-c;
  font-size: 1.25rem;
  font-weight: 600;
}

//SHOPPING TABLE
.shopping-table {
  width:100%;
  border: 1px solid $border-c;

  th {
    font-size: 1rem;
    font-weight: 700;
    background-color: $gray-c;
    line-height: 20px;
    padding: 15px 0;
    text-align: center;

    &:nth-of-type(1) {
      padding-left: 35px;
      text-align: left;
    }

    //&:nth-of-type(2),
    //&:nth-of-type(4) {
    //  text-align: center;
    //}
    //
    //&:last-of-type {
    //  text-align: right;
    //}
  }

  tr {
    border-bottom: 1px solid $border-c;
    &:last-child{
      border-bottom: none;
    }
    td{
      width: 17%;
      &:nth-of-type(1),
      &:nth-of-type(2){
        width: 24%;
      }
    }
  }

  .product-info {
    padding: 10px 10px 10px 35px;
    text-align: left;
    position: relative;
    height: 70px;

    .image-block {
      position: absolute;
      left: 0; top: 10px;
      width: 75px; height: 100px;

      img {
        width: auto; height: auto;
        max-width: 100%; max-height: 100%;
      }
    }

    h3 {
      font-size: 1rem;
      font-weight: 600;
      margin: 0;
      &:hover {
        color: $color1-c;
      }
    }
  }

  .product-qty {
    text-align: center;

    input {
      width: 40px;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
    }

    .add-remove {
      display: inline-block;
      margin-left: 10px;
      vertical-align: middle;
      position: relative;
      top: -3px;
      text-align: left;

      a {
        display: block;
        line-height: 30px;
        cursor: pointer;
        &:hover {
          color: $color1-c;
          text-decoration: underline;
        }
      }

      i {
        position: relative;
        top: 3px;
        display: inline-block;
        margin-left: 10px;
        font-size: 1.125rem;
      }
      .fa-window-close{
        color: $color1-c;
      }
    }
  }

  .product-price {
    text-align: center;
    font-size: 1rem;

    .decimal {
      position: relative;
      font-size: 0.6em;
      top: -0.5em;
    }

    .currency {
      text-transform: lowercase;
    }
  }

  .product-total {
    text-align: center;
    font-size: 1rem;

    .decimal {
      position: relative;
      font-size: 0.6em;
      top: -0.5em;
    }

    .price_currency {
      text-transform: lowercase;
    }
  }

  .product-features {
    > span {
      display: block;
    }

    span.title {
      font-weight: 700;
      display: inline-block;
      margin-right: 5px;
    }
  }
}

@include media-breakpoint-down(sm) {

  .shopping-table,
  .shopping-table thead,
  .shopping-table th,
  .shopping-table tbody,
  .shopping-table tr,
  .shopping-table td {
    display: block;
    clear: both;
  }

  .shopping-table {
    border-top: 1px solid $border-c;

    thead {
      visibility: hidden;
      opacity: 0;
      height: 0;
    }
    tr{
      td {
        width: 100%;
        position: relative;
        padding: 5px 0 5px 100px;
        &:nth-of-type(1),
        &:nth-of-type(2){
          width: 100%;
        }

        &:before {
          position: absolute;
          left: 10px;
          @include center(y);
          width: 100px;
          text-align: left;
          font-size: 0.875rem;
        }
      }
    }

    tr td:nth-of-type(1):before { content: "PROIZVOD"; }
    tr td:nth-of-type(2):before { content: "ODABRANO"; }
    tr td:nth-of-type(3):before { content: "CENA"; }
    tr td:nth-of-type(4):before { content: "KOLIČINA"; }
    tr td:nth-of-type(5):before { content: "UKUPNO"; }

    .product-info {
      padding: 10px 10px 10px 100px;

      .image-block {
        left: 50px;
      }

      .text-block {
        height: 50px;
        position: relative;
      }

      h3 {
        position: absolute;
        max-width: 100%;
        @include center(y);
      }
    }

    .product-qty {
      text-align: left;
    }

    .product-price {
      text-align: left;
    }

    .product-total {
      text-align: left;
    }

  }

}
@include media-breakpoint-only(xs) {

  .shopping-table {

    td {
      position: relative;
      padding: 5px 0 5px 80px;

      &:before {
        width: 80px;
        font-size: 12px;
      }
    }

    .product-info {
      padding: 10px 10px 10px 100px;
      text-align: left;
      height: auto;

      .image-block {
        top: 0;
        position: relative;
        left: 0;
        text-align: left ;
        margin: 0;
      }

      .text-block {
        clear: both;
        height: auto;
        position: relative;
        padding-top: 10px;
      }

      h3 {
        position: relative;
        max-width: 100%;
        @include translate(0, 0);
      }
    }

    .product-qty {

      .add-remove {
        margin-left: 10px;
        top: -2px;
      }
    }
  }
}

//ORDER TOTAL
.order-total-price {
  text-align: right;
  font-size: 1rem;
  //color: $color1-c;
  padding: 12px 50px 13px 0;
  border-right: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  font-weight: 700;
  .title{
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 400;
  }

  .decimal {
    position: relative;
    font-size: 0.6em;
    top: -0.5em;
  }

  .price_currency {
    text-transform: lowercase;
  }
  @include media-breakpoint-down(lg) {
    padding: 12px 35px 13px 0;
  }
  @include media-breakpoint-down(md) {
    padding: 12px 13px 13px 0;
  }
}

//SHOPPING CART OPTIONS (BUTTON/PROMO CODE BLOCK)
.shopping-cart-options {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .left-button {

  }

  .promo {
    flex-basis: 360px;

    div.c-flex {
      display: flex;
    }

    button {
      flex: 0 0 140px;
    }
  }

  .right-button {

  }

  @include media-breakpoint-down(md) {

    .promo {
      order: -1;
      flex-basis: 100%;
      margin-bottom: 20px;
    }
  }

  @include media-breakpoint-only(xs) {

    .left-button {
      flex-basis: 100%;
      margin-bottom: 20px;
    }

    .right-button {
      flex-basis: 100%;
    }
  }

}




//////////////////////////////////////////////////////////
//SECOND STEP (LOGIN/REGISTER/WITHOUT REGISTRATION)
//////////////////////////////////////////////////////////
.buying-process-step2 {

  .buying-options {

    h2 {
      display: table;
      width: 100%;
      height: 40px;
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 0;

      span {
        display: table-cell;
        line-height: 16px;
        vertical-align: middle;
      }
    }

    .account {
      padding: 25px 30px;
      border: 1px solid $border-c;
      margin-top: 30px;

      h3 {
        margin: 0 0 10px 0;
        font-size: 1.125rem;
        font-weight: 700;
      }
    }
    .registration{
      padding: 6px 15px;
      border: 1px solid $border-c;
      p{
        margin: 0;
      }
    }
    &.buying-options-register {
      h2 {
        margin-bottom: 30px;
      }
    }


    .forgotten-password-trigger {
      display: table;
      height: 40px;
      text-decoration: underline;
      float: left;
      cursor: pointer;
      margin-top: 20px;

      &:hover {
        color: $color1-c;
        text-decoration: underline;
      }

      span {
        display: table-cell;
        vertical-align: middle;
      }
    }

    .with-registration,
    .without-registration {
      padding: 20px 0;
    }

    .with-registration {
      border-bottom: 1px solid $border-c;
    }
  }

  @include media-breakpoint-down(sm) {
    .buying-options-register {
      margin-top: 40px;
    }
  }
}

//////////////////////////////////////////////////////////
//THIRD STEP (DELIVERY OPTIONS)
//////////////////////////////////////////////////////////
.buying-process-step3 {

  .block-title {
    margin-bottom: 0;
    font-size: 1.5rem;
    color: $black-c;
    font-weight: 700;
  }

  .block-subtitle {
    margin-bottom: 15px;
    font-size: 1.125rem;
    font-weight: 700;
  }

  .order{
    margin-bottom: 10px;
  }
  .about-customer {

    .block-title {
      margin-bottom: 25px;
    }

    .block-subtitle {

    }

    .customer-data {

      p {
        margin-bottom: 5px;

        > span {
          display: inline-block;
          width: 130px;
        }
      }

      .name {
        color: $color1-c;
        font-size: 1.125rem;
        margin-bottom: 10px;
      }

    }
    .second-address-checkbox {
      padding: 15px 0;
      border-top: 1px solid $border-c;
      border-bottom: 1px solid $border-c;
      margin: 15px 0;
    }
    .checkbox {
      label{
        padding: 5px 35px 0 35px;
        text-align: left;
        &:before{
          left: 0;
          right: auto;
        }

        &:after{
          left: 4px;
          right: auto;
        }
      }
    }
    .second-address-form {
      margin-bottom: 15px;
      border-bottom: 1px solid $border-c;
      padding-bottom: 20px;
      .note {

        .c-important {
          color: $color1-c;
        }

        .fa {
          color: $color1-c;
          position: relative;
          top: -5px;
        }
      }
    }
  }
  textarea{
    height: 180px;
    padding: 15px;
  }
  .about-order {

    .block-title {
      margin-bottom: 25px;
    }
    .order {
      .shopping-table {
        tr {
          td {
            width: 25%;
            @include media-breakpoint-down(sm) {
             width: 100%;
            }
          }
        }
      }
      @include media-breakpoint-down(sm) {
          tr td:nth-of-type(1):before { content: "PROIZVOD"; }
          tr td:nth-of-type(2):before { content: "CENA"; }
          tr td:nth-of-type(3):before { content: "KOLIČINA"; }
          tr td:nth-of-type(4):before { content: "UKUPNO"; }

      }
    }
    .block-subtitle-first {
      margin:0 0 20px 0;
      font-size: 1.375rem;
      text-transform: uppercase;
      font-weight: 700;
      border-top: 1px solid $border-c;
      padding-top: 20px;
      @extend .clearfix;

      > span {
        display: inline-block;
        float: right;

        .decimal {
          font-size: 0.6em;
          position: relative;
          top: -0.5em;
        }
        .price_currency{
          text-transform: lowercase;
        }

        @include media-breakpoint-up(lg) {
          font-size: 1.375rem;
        }
      }
      @include media-breakpoint-down(xs) {
        font-size: 1.0625rem;
      }
    }

    .note {
      border-top: 1px solid $border-c;
      border-bottom: 1px solid $border-c;
      padding: 30px 0;
      margin-bottom: 20px;

      .c-important {
        color: $color1-c;
      }
    }

    .payment-type {
      border-bottom: 1px solid $border-c;
      margin-bottom: 20px;
      padding-bottom: 10px;
      .radio-box {
        margin-top: -10px;

        label {
          margin: 0;
        }
      }
      .block-subtitle {
        margin-bottom: 10px;
        @extend .clearfix;

        > span {
          display: inline-block;
          float: right;
          color: $color1-c;

          .decimal {
            font-size: 0.6em;
            position: relative;
            top: -0.5em;
          }

          @include media-breakpoint-up(lg) {
            margin-top: -13px;
            font-size: 2rem;
          }
        }
      }
    }
    .promo{
      position: relative;
      .promo_code_notification{
        color: $color1-c;
      }
      .btn-t1{
        position: absolute;
        right: 0;
      }
    }

    @include media-breakpoint-down(sm) {
      margin-top: 40px;
    }
    .block-first-price{
      .first-price{
        font-size: 1.125rem;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
        p{
          margin-bottom: 0px;
        }
      }
      .delivery-blok {
        display: flex;
        justify-content: space-between;
        .delivery-blok-left{
          display: flex;
          .delivery-left{
            font-size: 1.125rem;
            margin-top: 7px;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 10px;
          }
          #payment_type {
            display: flex;
            .radio-option-holder {
              margin: 0 15px;
            }
          }
          .radio-box {
            label {
              margin: 0;
            }
          }
        }
        .delivery-blok-right{
          p{
            font-size: 1.125rem;
            font-weight: 600;
          }
        }
      }
      .delivery-price{
        text-transform: lowercase;
      }
    }
  }

  .second-address {
    padding-top: 20px;

    .second-address-checkbox label {
      color: $color1-c;
      text-align: left;
    }

    .second-address-form {
      margin-top: 15px;
    }

    .note {

      .c-important {
        color: $color1-c;
      }

      .fa {
        color: $color1-c;
        position: relative;
        top: -5px;
      }
    }
  }

}

//////////////////////////////////////////////////////////
//FOURTH STEP (SHOPPING DONE)
//////////////////////////////////////////////////////////
.buying-process-step4 {

  .text-block {
    max-width: 560px;
    margin: 0 auto;
    text-align: center;
  }

  h2 {
    font-size: 2rem;
    color: $color1-c;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  p {
    font-size: 1rem;
  }

  a {
    text-decoration: underline;

    &:hover {
      color: $color1-c;
      text-decoration: underline;
    }
  }

}
.shop-cart {
  width: 100%;

  .cart-preview {
    display: flex;

    .icon {
      padding-left: 0;
      padding-right: 0;
      flex: 0 0 40px;
    }
    .data {
      border: 1px solid $border-c;
      border-left: 0;line-height: 20px;
      padding: 9px 15px;
      text-transform: uppercase;
      font-weight: 700;
      width: 100%;
    }
    .items-qty {
      display: inline-block;
      margin-right: 10px;
    }
  }
  @include media-breakpoint-up(sm) {
    width: 240px;
  }

  @include media-breakpoint-down(xs){
    left: auto;
    right: 0;
    width: 100% !important;
    position: absolute !important;
    top: 77px;
    #shop-cart{
      float: right;
      margin-top: -51px;
      margin-right: 10px;
    }
    .cart-preview {
      width: 40px;
      height: 40px;
      z-index: 10;
      display: inline-block;
      position: relative;

      .icon {
        display: inline-block;
        width: 40px; height: 40px;
        font-size: 1.5rem;
      }
      .data {
        padding: 0;
        border: 0;
        width: 0;
        .icon-arrow {
          display: none;
        }
      }
      .items-qty {
        display: inline-block;
        border: 1px solid #ddd;
        background-color: #fff;
        margin: 0;
        min-width: 17px;
        height: 17px;
        line-height: 8px;
        padding: 4px;
        text-align: center;
        border-radius: 50%;
        top: -1px;
        right: -5px;
        position: absolute;

        span {
          display: none;
        }
      }
      .total-price {
        display: none;
      }
    }
  }
}


@-webkit-keyframes fadeInDownCart {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(310px, -100%, 0);
    transform: translate3d(310px, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform:translate3d(310px, 0, 0);
    transform: translate3d(310px, 0, 0);
  }
}

@keyframes fadeInDownCart {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(310px, -100%, 0);
    transform: translate3d(310px, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform:translate3d(310px, 0, 0);
    transform: translate3d(310px, 0, 0);
  }
}

@include media-breakpoint-only(lg) {
  @-webkit-keyframes fadeInDownCart {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(225px, -100%, 0);
      transform: translate3d(225px, -100%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: translate3d(225px, 0, 0);
      transform: translate3d(225px, 0, 0);
    }
  }

  @keyframes fadeInDownCart {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(225px, -100%, 0);
      transform: translate3d(225px, -100%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: translate3d(225px, 0, 0);
      transform: translate3d(225px, 0, 0);
    }
  }
}
@include media-breakpoint-only(md) {
  @-webkit-keyframes fadeInDownCart {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(105px, -100%, 0);
      transform: translate3d(105px, -100%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: translate3d(105px, 0, 0);
      transform: translate3d(105px, 0, 0);
    }
  }

  @keyframes fadeInDownCart {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(105px, -100%, 0);
      transform: translate3d(105px, -100%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: translate3d(105px, 0, 0);
      transform: translate3d(105px, 0, 0);
    }
  }
}
@include media-breakpoint-only(sm) {
  @-webkit-keyframes fadeInDownCart {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(20px, -100%, 0);
      transform: translate3d(20px, -100%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: translate3d(20px, 0, 0);
      transform: translate3d(20px, 0, 0);
    }
  }

  @keyframes fadeInDownCart {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(20px, -100%, 0);
      transform: translate3d(20px, -100%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: translate3d(20px, 0, 0);
      transform: translate3d(20px, 0, 0);
    }
  }
}
@include media-breakpoint-only(xs) {
  @-webkit-keyframes fadeInDownCart {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes fadeInDownCart {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
}

.fadeInDownCart {
  -webkit-animation-name: fadeInDownCart;
  animation-name: fadeInDownCart;
}

.fixed-header {
  .shop-cart {
    -webkit-animation-duration: 600ms;
    animation-duration: 600ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInDownCart;
    animation-name: fadeInDownCart;
    z-index: 10;
    position: fixed!important;
    left: 50%; top: 90px;

    .cart-preview{
      .icon{
        font-size: 1.5rem;
      }
    }

    .data {
      background-color: $white-c;
    }

    @include media-breakpoint-down(sm) {
      top: 56px;
      z-index: 12;
      //width: 40px;
      right: 10px;
      #shop-cart{
        float: right;
        margin-top: -45px;
        margin-right: 10px;
      }
    }
    @include media-breakpoint-down(xs) {
      left: auto;
      right: 0;
      width: 100% !important;
    }
  }

  @include media-breakpoint-down(sm){
    .cart-preview {
      width: 40px;
      height: 40px;
      -webkit-animation-duration: 600ms;
      animation-duration: 600ms;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation-name: fadeInDown;
      animation-name: fadeInDown;
      z-index: 10;
      display: inline-block;

      .icon {
        display: inline-block;
        width: 40px; height: 40px;
      }
      .data {
        padding: 0;
        border: 0;
        width: 0;
        .icon-arrow {
          display: none;
        }
      }
      .items-qty {
        display: inline-block;
        border: 1px solid #ddd;
        background-color: #fff;
        margin: 0;
        min-width: 17px;
        height: 17px;
        line-height: 8px;
        padding: 4px;
        text-align: center;
        border-radius: 50%;
        top: -1px;
        right: -5px;
        position: absolute;

        span {
          display: none;
        }
      }
      .total-price {
        display: none;
      }
    }
  }

}


.shop-cart {
  position: relative!important;

  .shop-cart-products {
    width: 300px;
    border: none;
    /* Firefox v3.5+ */
    -moz-box-shadow:-1px -1px 10px rgba(0,0,0,0.3);
    /* Safari v3.0+ and by Chrome v0.2+ */
    -webkit-box-shadow:-1px -1px 10px rgba(0,0,0,0.3);
    /* Firefox v4.0+ , Safari v5.1+ , Chrome v10.0+, IE v10+ and by Opera v10.5+ */
    box-shadow:-1px -1px 10px rgba(0,0,0,0.3);
    -ms-filter:"progid:DXImageTransform.Microsoft.dropshadow(OffX=-1,OffY=-1,Color=#4d000000,Positive=true)";
    filter:progid:DXImageTransform.Microsoft.dropshadow(OffX=-1,OffY=-1,Color=#4d000000,Positive=true);
    background-color: $white-c;
    padding: 20px;
  }

  .cart-product-list {
    padding-right: 20px;
  }

  .product {
    position: relative;
    padding-left: 95px;
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .item-preview{
      position: absolute;
      left: 0; top: 0;
      display: inline-block;
      width: 70px; height: 90px;
      border: 1px solid $gray-c;
      box-sizing: content-box;
    }

    img{
      height: auto; width: auto;
      max-height: 100%; max-width: 100%;
    }

    .right-part{
      position: relative;
      padding-bottom: 60px;
      min-height: 130px;
      box-sizing: border-box;
      & a:hover{
        color: $color1-c;
      }
    }

    h4{
      font-size: 14px;
    }

    .product-features {
      font-size: 0.75rem;
      margin-top: 10px;

      > span {
        display: block;
      }

      span.title {
        font-weight: 700;
        display: inline-block;
        margin-right: 5px;
      }
    }

    .item-cat{
      position: absolute;
      left: 0; bottom: 10px;
      display: block;
      font-weight: 600;
      font-size: 14px;
      color: $color2-c;
      &:hover {
        color: $color1-c;
      }
    }

    .item-price{
      position: absolute;
      left: 0; bottom: 10px;
      display: block; width: 100%;
      text-align: right;
      font-size: 14px;
      font-weight: 600;
      color: $color2-c;
      .price_decimal{
        font-size: 0.6em;
        position: relative;
        top: -0.6em;
      }
      .price_currency{
        font-weight: 600;
        text-transform: lowercase;
      }
    }

    .remove-item{
      position: absolute;
      right: 0;
      bottom: 35px;
    }

    &:before{
      content: '';
      position: absolute;
      bottom: 0; right: 0; top: 100%; left: 0;
      height: 1px;
      background-color: $gray-c;
    }

  }

  .shop-cart-total{
    line-height: 60px;
    text-align: center;
    color: $white-c;
    background-color: $color2-c;
    font-size: 20px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 20px;
    text-transform: uppercase;
    margin-top: 20px;
    font-weight: 700;

    .title {

    }

    & .price_decimal{
      font-size: 0.6em;
      position: relative;
      top: -0.7em;
    }
    & .price_currency{
      font-weight: 600;
      text-transform: lowercase;
    }
  }

  .cart-order a{
    width: 100%;
    line-height: 30px;

    &.button-change{
      margin-right: -5px;
    }
    &.button-order{
      margin-left: -5px;
    }
  }
}
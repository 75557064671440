.c-slider {
  position: relative;
}

.c-slider-arr {
  position: absolute;
  display: inline-block;
  text-align: center;
  top: 50%;
  cursor: pointer;
  z-index: 1;
  @include translate(0, -50%);
}

//////////////////////////////
//HOMEPAGE SLIDER
//////////////////////////////
.homepage-slider {
  .c-slider-arr {
    color: $color2-c;
    background: rgba(247, 247, 247, 0.7);
    line-height: 65px;
    width: 70px;
    height: 70px;
    font-size: 60px;
    &.prev-slide {
      left: 40px;
    }
    &.next-slide {
      right: 40px;
    }
    &:hover {
      color: $color1-c !important;
      border-color: $color1-c;
    }
  }

  .slick-slide {
    position: relative;
    overflow: hidden;
  }

  .text-block-holder {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;

    > .container {
      position: relative;
      height: 100%;
    }
  }

  .text-block {
    padding: 20px;
    display: inline-block;
    white-space: nowrap;
    background-color: rgba($white-c, 0.5);
    position: absolute;
    @include center(y);
    left: 15px;
    opacity: 0;
    @include transition(opacity 2s 0.5s);
  }

  .text1 {
    font-size: 2.625rem;
    font-weight: 600;
    color: $color1-c;
    text-transform: uppercase;

    position: relative;
    opacity: 0;
    left: -300%;
    z-index: -1;
    @include transition(opacity 2s 1s, left 2s);
  }

  .text2 {
    font-size: 1rem;
    line-height: 1.15;
    margin-top: 10px;

    position: relative;
    opacity: 0;
    left: 300%;
    z-index: -1;
    @include transition(opacity 2s 1s, left 2s);
  }

  .link-block {
    position: relative;
    margin-top: 20px;

    a {
      position: relative;
      top: 300%;
      opacity: 0;
      @include transition(opacity 2s 1.5s, top 2s);
    }
  }

  .slick-active {
    .text-block {
      opacity: 1;
    }

    .text1,
    .text2 {
      opacity: 1;
      left: 0;
      z-index: 1;
    }

    .link-block a {
      opacity: 1;
      top: 0;
      z-index: 1;
    }
  }
}

.homepage-slider {
  height: 400px;

  .slick-slide {
    height: 400px;
  }
}

@include media-breakpoint-between(md, lg) {
  .homepage-slider {
    .text-block {
      padding: 20px 10px;
    }
  }

  .homepage-slider {
    height: 400px;

    .slick-slide {
      height: 400px;
    }
  }
}

@include media-breakpoint-only(sm) {
  .homepage-slider {
    .text-block {
      padding: 15px 10px;
    }

    .text1 {
      font-size: 2rem;
    }
  }

  .homepage-slider {
    height: 300px;

    .slick-slide {
      height: 300px;
    }
  }
}

@include media-breakpoint-only(xs) {
  .homepage-slider {
    .c-slider-arr {
      display: none !important;
    }

    .text-block {
      padding: 15px 10px;
      left: 50%;
      white-space: unset;
      @include translate(-50%, -50%);
      width: 90%;
    }

    .text1 {
      font-size: 1.5rem;
      margin-top: 5px;
    }

    .text2 {
    }

    .link-block a {
      line-height: 15px;
      padding: 3px 10px;
      font-size: 10px;
    }
  }

  .homepage-slider {
    height: 250px;

    .slick-slide {
      height: 250px;
    }
  }
}

//////////////////////////////
//PRODUCT TABS SLIDER
//////////////////////////////
.product-tabs {
  margin-bottom: 70px;

  .c-slider-arr {
    top: 50%;
    transform: translateY(-50%);
    color: $color2-c;
    border-color: $color2-c;
    line-height: 20px;
    width: 30px;
    font-size: 50px;
    &.prev-slide {
      left: -25px;
      //&:before {
      //  content: '';
      //  position: absolute;
      //  right: -1px; top: 0;
      //  height: 20px; width: 2px;
      //  background-color: $color2-c;
      //}
    }
    &.next-slide {
      right: -25px;
    }
    &:hover {
      color: $color1-c !important;
    }
  }
  @include media-breakpoint-down(md) {
    margin-bottom: 20px;
  }
  @include media-breakpoint-down(xs) {
    .c-slider-arr {
      &.prev-slide {
        left: 0;
      }
      &.next-slide {
        right: 0;
      }
    }
  }
}

.tabs-slider {
  .c-slide-pos {
    padding: 0 15px;
  }
}

.tab-pane {
  visibility: hidden;
  opacity: 0;
  height: 0;
  @include transition-all(0.4s);
  &.active {
    visibility: visible;
    opacity: 1;
    height: auto;
  }
}
.categories-slider{
  .c-slider-arr {
    top: calc(50% + 20px);
    transform: translateY(-50%);
    color: $color2-c;
    border-color: $color2-c;
    line-height: 20px;
    width: 30px;
    font-size: 50px;
    &.prev-slide {
      left: -35px;
    }
    &.next-slide {
      right: -35px;
    }
    &:hover {
      color: $color1-c !important;
    }
  }
  @include media-breakpoint-down(xs) {
    .c-slider-arr {
      &.prev-slide {
        left: 0;
      }
      &.next-slide {
        right: 0;
      }
    }
  }
}
//////////////////////////////
//PRODUCTS SLIDER
//////////////////////////////
.products-slider-section {
  padding-top: 50px;
  // padding-bottom: 100px;
  //border-bottom: 1px solid $border-c;
  @include media-breakpoint-down(md) {
    padding: 20px 0;
  }
}

.products-slider {
  margin-top: 50px;

  .c-slide-pos {
    padding: 1px 15px;
  }

  .c-slider-arr {
    top: 50%;
    transform: translateY(-50%);
    color: $color2-c;
    border-color: $color2-c;
    line-height: 20px;
    width: 30px;
    font-size: 50px;
    &.prev-slide {
      left: -25px;
      //&:before {
      //  content: '';
      //  position: absolute;
      //  right: -1px; top: 0;
      //  height: 20px; width: 2px;
      //  background-color: $color2-c;
      //}
    }
    &.next-slide {
      right: -25px;
    }
    &:hover {
      color: $color1-c !important;
    }
  }
  @include media-breakpoint-down(md) {
    margin-top: 20px;
  }
  @include media-breakpoint-down(xs) {
    .c-slider-arr {
      &.prev-slide {
        left: 0;
      }
      &.next-slide {
        right: 0;
      }
    }
  }
}

.blog-preview-slider {
  margin-top: 50px;

  .slick-slide {
    margin: 0 15px;
  }

  /* the parent */
  .slick-list {
    margin: 0 -15px;
  }

  .c-slider-arr {
    top: 50%;
    transform: translateY(-50%);
    color: $color2-c;
    border-color: $color2-c;
    line-height: 20px;
    width: 30px;
    font-size: 50px;
    &.prev-slide {
      left: -35px;
      //&:before {
      //  content: '';
      //  position: absolute;
      //  right: -1px; top: 0;
      //  height: 20px; width: 2px;
      //  background-color: $color2-c;
      //}
    }
    &.next-slide {
      right: -35px;
    }
    &:hover {
      color: $color1-c !important;
    }
  }
  @include media-breakpoint-down(md) {
    margin-top: 20px;
  }
  @include media-breakpoint-down(xs) {
    .image-block{
      margin-bottom: 0;
    }
    .c-slider-arr {
      &.prev-slide {
        left: 0;
      }
      &.next-slide {
        right: 0;
      }
    }
  }
}

//////////////////////////////
//TESTIMONIALS SLIDER
//////////////////////////////
.testimonials-slider {
  padding-bottom: 80px;

  .testimonials-item {
    min-height: 140px;
    padding-bottom: 10px;
    border-bottom: 1px solid $border-c;
    margin-bottom: 50px;

    .c-flex {
      display: flex;
    }
    .image-block {
      flex: 0 0 70px;
      height: auto;
    }
    .text {
      padding-left: 40px;
      p {
        position: relative;
        margin-bottom: 20px;
        font-size: 0.75rem;
        &:before {
          content: "\f10d";
          font-family: FontAwesome;
          position: absolute;
          left: -20px;
          top: 0;
          color: $color1-c;
          font-size: 14px;
        }
      }
      .name {
        display: block;
        font-style: italic;
        font-size: 0.75rem;
      }
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }
  }

  .c-slider-arr {
    top: auto;
    bottom: 50px;
    right: 0;
    color: $color2-c;
    border-color: $color2-c;
    line-height: 20px;
    width: 30px;
    font-size: 20px;
    @include translate(0, 0);
    &.prev-slide {
      right: 30px;
      &:before {
        content: "";
        position: absolute;
        right: -1px;
        top: 0;
        height: 20px;
        width: 2px;
        background-color: $color2-c;
      }
    }
    &.next-slide {
      right: 0;
    }
    &:hover {
      color: $color1-c !important;
    }
  }
}

//////////////////////////////
//BRANDS SLIDER
//////////////////////////////
.brands-section {
  .content-holder {
    padding: 60px 0;
  }
  h2 {
    text-align: center;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 60px;
    text-indent: 0;
  }
}

.brands-slider {
  .c-slide-pos {
    padding: 0 5px;
    a {
      display: block;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }

  .c-slider-arr {
    top: -50px;
    left: 50%;
    color: $color2-c;
    border-color: $color2-c;
    line-height: 30px;
    width: 40px;
    font-size: 30px;
    text-indent: 0;
    @include translate(0, 0);
    &.prev-slide {
      margin-left: -40px;
      &:before {
        content: "";
        position: absolute;
        right: -2px;
        top: 0;
        height: 30px;
        width: 4px;
        background-color: $color2-c;
      }
    }
    &.next-slide {
      right: 0;
    }
    &:hover {
      color: $color1-c !important;
    }
  }
}

//////////////////////////////
//BEST SELLERS SLIDER (ON PRODUCT PAGE)
//AND FEATURED PRODUCTS (ON PRODUCT LIST PAGE)
//////////////////////////////
.best-sellers,
.featured-products,
.recommended-products {
  .block-title {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 20px;
  }
}

.best-sellers-slider,
.featured-products-slider,
.recommended-products-slider {
  .c-slider-arr {
    top: -35px;
    color: $black-c;
    border-color: $black-c;
    line-height: 20px;
    width: 30px;
    font-size: 20px;
    &.prev-slide {
      right: 30px;
      &:before {
        content: "";
        position: absolute;
        right: -1px;
        top: 0;
        height: 20px;
        width: 2px;
        background-color: $black-c;
      }
    }
    &.next-slide {
      right: 0;
    }
    &:hover {
      color: $color1-c !important;
    }
  }

  .slick-slide {
    position: relative;
    padding-left: 90px;
    min-height: 110px;
    padding-bottom: 10px;
  }

  .image-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 75px;
    height: 100px;
  }

  .product-name {
    font-size: 0.875rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0.5rem;

    a:hover {
      color: $color1-c;
    }
  }

  .price-block {
    font-weight: 700;

    .price {
      display: block;
      color: $color1-c;
      font-size: 0.875rem;
      margin: 0 0;
    }

    .old-price {
      display: inline-block;
      padding: 0 5px;
      position: relative;
      font-size: 0.75rem;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
        background-color: $black-c;
      }
    }
  }
  .large-button {
    font-size: 0.75rem;
    text-transform: uppercase;
    color: $white-c;
    background-color: $color1-c;
    text-align: center;
    line-height: 30px;
    display: block;
    width: 100%;
    height: 30px;
    border: 1px solid $color1-c;
    cursor: pointer;
    font-weight: 700;
    margin-top: 10px;
    @include transition-all(0.4s);
    &:hover {
      background-color: $black-c;
      color: $white-c;
      border: 1px solid $black-c;
    }
  }
}

//////////////////////////////
//RELATED BLOG SLIDER
//////////////////////////////
.related-blog-slider {
  /* the slides */
  .slick-slide {
    margin: 0 10px;
  }
  /* the parent */
  .slick-list {
    margin: 0 -10px;
  }

  .c-slider-arr {
    top: -65px;
    left: 95%;
    color: $white-c !important;
    width: 30px;
    height: 40px;
    line-height: 37px;
    font-size: 20px;
    @include translate(0, 0);
    &.prev-slide {
      margin-left: -30px;
      &:before {
        content: "";
        position: absolute;
        right: -1px;
        top: 9px;
        height: 20px;
        width: 2px;
        background-color: $white-c;
      }
    }
    &.next-slide {
      right: 0;
    }
    &:hover {
      color: $color1-c !important;
    }
    @include media-breakpoint-down(xs) {
      left: 90%;
    }
  }
}
